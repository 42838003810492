/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GoogleApisYouTubeV3DataResourceId } from './GoogleApisYouTubeV3DataResourceId';
import {
    GoogleApisYouTubeV3DataResourceIdFromJSON,
    GoogleApisYouTubeV3DataResourceIdFromJSONTyped,
    GoogleApisYouTubeV3DataResourceIdToJSON,
} from './GoogleApisYouTubeV3DataResourceId';
import type { GoogleApisYouTubeV3DataThumbnailDetails } from './GoogleApisYouTubeV3DataThumbnailDetails';
import {
    GoogleApisYouTubeV3DataThumbnailDetailsFromJSON,
    GoogleApisYouTubeV3DataThumbnailDetailsFromJSONTyped,
    GoogleApisYouTubeV3DataThumbnailDetailsToJSON,
} from './GoogleApisYouTubeV3DataThumbnailDetails';

/**
 * 
 * @export
 * @interface GoogleApisYouTubeV3DataPlaylistItemSnippet
 */
export interface GoogleApisYouTubeV3DataPlaylistItemSnippet {
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    channelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    channelTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    playlistId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    position?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    publishedAtRaw?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    publishedAt?: Date | null;
    /**
     * 
     * @type {GoogleApisYouTubeV3DataResourceId}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    resourceId?: GoogleApisYouTubeV3DataResourceId;
    /**
     * 
     * @type {GoogleApisYouTubeV3DataThumbnailDetails}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    thumbnails?: GoogleApisYouTubeV3DataThumbnailDetails;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    videoOwnerChannelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    videoOwnerChannelTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataPlaylistItemSnippet
     */
    eTag?: string | null;
}

/**
 * Check if a given object implements the GoogleApisYouTubeV3DataPlaylistItemSnippet interface.
 */
export function instanceOfGoogleApisYouTubeV3DataPlaylistItemSnippet(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GoogleApisYouTubeV3DataPlaylistItemSnippetFromJSON(json: any): GoogleApisYouTubeV3DataPlaylistItemSnippet {
    return GoogleApisYouTubeV3DataPlaylistItemSnippetFromJSONTyped(json, false);
}

export function GoogleApisYouTubeV3DataPlaylistItemSnippetFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleApisYouTubeV3DataPlaylistItemSnippet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channelTitle': !exists(json, 'channelTitle') ? undefined : json['channelTitle'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'playlistId': !exists(json, 'playlistId') ? undefined : json['playlistId'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'publishedAtRaw': !exists(json, 'publishedAtRaw') ? undefined : json['publishedAtRaw'],
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (json['publishedAt'] === null ? null : new Date(json['publishedAt'])),
        'resourceId': !exists(json, 'resourceId') ? undefined : GoogleApisYouTubeV3DataResourceIdFromJSON(json['resourceId']),
        'thumbnails': !exists(json, 'thumbnails') ? undefined : GoogleApisYouTubeV3DataThumbnailDetailsFromJSON(json['thumbnails']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'videoOwnerChannelId': !exists(json, 'videoOwnerChannelId') ? undefined : json['videoOwnerChannelId'],
        'videoOwnerChannelTitle': !exists(json, 'videoOwnerChannelTitle') ? undefined : json['videoOwnerChannelTitle'],
        'eTag': !exists(json, 'eTag') ? undefined : json['eTag'],
    };
}

export function GoogleApisYouTubeV3DataPlaylistItemSnippetToJSON(value?: GoogleApisYouTubeV3DataPlaylistItemSnippet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channelId': value.channelId,
        'channelTitle': value.channelTitle,
        'description': value.description,
        'playlistId': value.playlistId,
        'position': value.position,
        'publishedAtRaw': value.publishedAtRaw,
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt === null ? null : value.publishedAt.toISOString()),
        'resourceId': GoogleApisYouTubeV3DataResourceIdToJSON(value.resourceId),
        'thumbnails': GoogleApisYouTubeV3DataThumbnailDetailsToJSON(value.thumbnails),
        'title': value.title,
        'videoOwnerChannelId': value.videoOwnerChannelId,
        'videoOwnerChannelTitle': value.videoOwnerChannelTitle,
        'eTag': value.eTag,
    };
}

