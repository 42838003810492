export function EmbedSpotifyShow(
    props: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>,
) {
    return (
        <iframe
            style={{ borderRadius: '12px' }}
            src="https://open.spotify.com/embed/show/0tSeh10ir9tLhYsI6jVt0l?utm_source=generator&t=0"
            width="100%"
            height="352"
            frameBorder="0"
            // allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            title="Code Insider's Podcast"
            {...props}
        ></iframe>
    );
}

export function EmbedSpotifyEpisode({
    episodeId,
    ...props
}: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> & { episodeId: string }) {
    return (
        <iframe
            style={{ borderRadius: '12px' }}
            src={`https://open.spotify.com/embed/episode/${episodeId}?utm_source=generator&theme=0`}
            width="100%"
            height="152"
            frameBorder="0"
            // allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            title="Code Insider's Podcast's Episode"
            {...props}
        ></iframe>
    );
}
