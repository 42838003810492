import React from 'react';
import { Carousel, Image } from 'react-bootstrap';

import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import Button from '../../Button';
import Code from '../../Code';
import Header from '../../Header';
import TriangleContainer from '../../TriangleContainer';
import Alexis from './Images/alexis.jpg';
import Christopher from './Images/christopher.jpg';
import Christopher1 from './Images/christopher.png';
import InsiderGrow1 from './Images/croissance-1.png';
import InsiderGrow2 from './Images/croissance-2.png';
import InsiderGrow3 from './Images/croissance-3.png';
import Francois from './Images/francois.jpg';
import HeaderImage from './Images/header.png';
import InsiderGrowFull from './Images/insider-grow.png';
import Insider from './Images/insider.png';
import Kevin from './Images/kevin.jpg';
import Wissem from './Images/wissem.png';
import './index.css';

const InsiderCode = [
    '<blue>namespace</blue> CodeInsider',
    '{',
    '   <blue>class</blue> Postulat : Jobs',
    '   {',
    '       <blue>private string</blue> prenom;',
    '       <blue>private string</blue> nom;',
    '       <blue>private string</blue> email;',
    '       <blue>private string</blue> description;',
    '',
    '       <blue>public</blue> Postulat()',
    '       {',
    '           <green>this</green>.nom = ',
    '           <green>this</green>.prenom = ',
    '           <green>this</green>.email = ',
    '           <green>this</green>.description = ',
    '       }',
    '   }',
    '}',
];

export default function PageInsider() {
    useFormattedDocumentTitle("Qu'est ce qu'un Insider");

    return (
        <>
            <Header title="Qu'est ce qu'un" subTitle="Insider" image={HeaderImage} />
            <div className="splitContainer" style={{ marginBottom: '2em' }}>
                <div>
                    <Image
                        className="imageSizingSplit"
                        style={{ maxWidth: '250px' }}
                        src={Insider}
                        alt="Je suis un Insider"
                    />
                </div>
                <div>
                    <h1>Apprendre à apprendre</h1>
                    <p className="textSizingSplit text-justify" style={{ paddingBottom: '0' }}>
                        Plusieurs choses sont essentielles afin de parfaire son art, d’abord vouloir continuellement
                        monter en compétence et partager ses connaissances, avoir également une agilité intellectuelle,
                        car les langages, les manières de faire évoluent sans cesse ; enfin être constamment prêt à se
                        remettre en question, pour trouver la meilleure solution possible.
                        <br />
                        <br />
                        Dans ce sens, Code Insider privilégie les personnes ayant une bonne capacité à apprendre,
                        souhaitant se transformer progressivement en Software Crafts(wo)man, et maitriser les bonnes
                        pratiques. Pour cela la curiosité intellectuelle est un atout précieux.
                    </p>
                </div>
            </div>
            <Image className="imgCroissanceFull" src={InsiderGrowFull} alt="La Croissance d'un Insider" />
            <Image className="imgCroissance" src={InsiderGrow1} alt="La Croissance d'un Insider" />
            <Image className="imgCroissance" src={InsiderGrow2} alt="La Croissance d'un Insider" />
            <Image className="imgCroissance" src={InsiderGrow3} alt="La Croissance d'un Insider" />
            <div style={{ backgroundColor: '#014f9b', padding: '0 5% 10% 5%' }}>
                <h1 className="text-white">Challenges rencontrés en missions</h1>
                <div id="containerInsider">
                    <div id="insiderCode">
                        <Code content={InsiderCode} typeSpeed={30} />
                    </div>
                    <div id="insiderQuote">
                        <Carousel>
                            <Carousel.Item>
                                <p className="textSizingSplitInsider text-justify">
                                    ,,
                                    <br />
                                    Développement d'une solution à destinations des hôpitaux dans le cadre de
                                    prescriptions médicamenteuses.
                                    <br />
                                    <span style={{ float: 'right' }}>,,</span>
                                </p>
                                <Image src={Kevin} alt="Kévin" />
                                <div className="name">
                                    <h4>
                                        Kévin
                                        <br />
                                        Craftsman
                                    </h4>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <p className="textSizingSplitInsider text-justify">
                                    ,,
                                    <br />
                                    Refonte et développement de nouveaux modules d’un CRM à destination des commerciaux
                                    d’une grande banque.
                                    <br />
                                    <span style={{ float: 'right' }}>,,</span>
                                </p>
                                <Image src={Christopher} alt="Christopher" />
                                <div className="name">
                                    <h4>
                                        Christopher
                                        <br />
                                        Craftsman
                                    </h4>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <p className="textSizingSplitInsider text-justify">
                                    ,,
                                    <br />
                                    Développement de nouvelles fonctionnalités pour le tunnel d’achat d’un site
                                    e-commerce à fort traffic.
                                    <br />
                                    <span style={{ float: 'right' }}>,,</span>
                                </p>
                                <Image src={Alexis} alt="Alexis" />
                                <div className="name">
                                    <h4>
                                        Alexis
                                        <br />
                                        Craftsman
                                    </h4>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <Button variant="light" link="/recruiting">
                    Nous rejoindre
                </Button>
            </div>
            <div style={{ marginTop: '-1%' }}>
                <TriangleContainer variant="light">
                    <h1>Nos Insiders témoignent</h1>
                    <div className="testimonySplitContainer" id="insiderVideoList">
                        <div className="testimonyContainer">
                            <iframe
                                src="https://www.youtube.com/embed/LeD_nHhJAHw"
                                id="videoSize"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                width="350"
                                height="200"
                                frameBorder="0"
                                title="useless 1"
                            />
                            <div className="description">
                                <Image src={Francois} alt="François" />
                                <div>
                                    <h5>C'est quoi le Software Craftsmanship ?</h5>
                                    <p>Plus qu'une méthode, une passion !</p>
                                </div>
                            </div>
                        </div>
                        <div className="testimonyContainer">
                            <iframe
                                src="https://www.youtube.com/embed/GYN_srjAvyk"
                                id="videoSize"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                width="350"
                                height="200"
                                frameBorder="0"
                                title="useless 2"
                            />
                            <div className="description">
                                <Image src={Christopher1} alt="Christopher" />
                                <div>
                                    <h5>Interview de Chris</h5>
                                    <p>Implémenter le BDD avec .NET 5 (en anglais)</p>
                                </div>
                            </div>
                        </div>
                        <div className="testimonyContainer">
                            <iframe
                                src="https://www.youtube.com/embed/zb4yvQ029wI"
                                id="videoSize"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                width="350"
                                height="200"
                                frameBorder="0"
                                title="useless 3"
                            />
                            <div className="description">
                                <Image src={Wissem} alt="Wissem" />
                                <div>
                                    <h5>Le choix de missions</h5>
                                    <p>Software Craftsmanship : ce qu'on cherche chez nos futurs Insiders</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </TriangleContainer>
            </div>
        </>
    );
}
