import React, { Component } from 'react';

import './index.css';

function extractTagContent(code, cursor) {
    let str = '';

    while (code[cursor] !== '>') str += code[cursor++];
    return [cursor, str];
}

export default class Code extends Component {
    componentDidMount() {
        let code = this.raw.innerHTML;
        let cursor = 0;
        let lastTag = null;
        let span = document.createElement('span');
        this.raw.innerHTML = '';

        span.innerHTML = '|';
        if (!this.raw.append) return; //Chrome 41 does not support append so by definition does not support code animation
        this.raw.append(span);
        let interval = setInterval(() => {
            this.raw.removeChild(span);
            let char = code[cursor];
            if (char === '<') {
                let data = extractTagContent(code, cursor + 1);
                cursor = data[0] + 1;
                let tagName = data[1];
                if (tagName[0] === '/') lastTag = null;
                else {
                    lastTag = document.createElement('span');
                    lastTag.className = tagName;
                    this.raw.append(lastTag);
                }
            } else {
                if (lastTag !== null) lastTag.innerHTML += char;
                else this.raw.innerHTML += char;
                ++cursor;
            }
            this.raw.append(span);
            if (cursor >= code.length) {
                clearInterval(interval);
                this.raw.removeChild(span);
            }
        }, this.props.typeSpeed);
    }

    contentAsSingleLine() {
        return this.props.content.join('\n');
    }

    render() {
        return (
            <pre
                ref={(e) => (this.raw = e)}
                className="codeBlock"
                dangerouslySetInnerHTML={{ __html: this.contentAsSingleLine() }}
            ></pre>
        );
    }
}
