import { type Video } from '../../Apis/youtube';

export type YoutubeEmbedProps = {
    title: string;
    videoId: string;
};

export function YoutubeEmbed({
    title,
    videoId,
    ...props
}: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> & YoutubeEmbedProps) {
    return (
        <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            // allowfullscreen
            {...props}
        ></iframe>
    );
}

export function YoutubeEmbedPlaylist() {
    return (
        <iframe
            className="rounded-xl"
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/videoseries?list=PLa1zcPbERz4OOtFsHB_tJy_H4UUcbPn7R"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
        ></iframe>
    );
}
