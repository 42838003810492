/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpotifyAPIWebImage
 */
export interface SpotifyAPIWebImage {
    /**
     * 
     * @type {number}
     * @memberof SpotifyAPIWebImage
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof SpotifyAPIWebImage
     */
    width?: number;
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebImage
     */
    url?: string | null;
}

/**
 * Check if a given object implements the SpotifyAPIWebImage interface.
 */
export function instanceOfSpotifyAPIWebImage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SpotifyAPIWebImageFromJSON(json: any): SpotifyAPIWebImage {
    return SpotifyAPIWebImageFromJSONTyped(json, false);
}

export function SpotifyAPIWebImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpotifyAPIWebImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'height': !exists(json, 'height') ? undefined : json['height'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function SpotifyAPIWebImageToJSON(value?: SpotifyAPIWebImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'height': value.height,
        'width': value.width,
        'url': value.url,
    };
}

