import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { ApplicationForm } from './ApplicationForm';
import { getJobOffers } from './api';
import { convertRawStateToHtml } from './draftjs';

export default function JobOfferPage() {
    const [showApplicationForm, setShowApplicationForm] = useState(false);

    const { id } = useParams<{ id: string }>();

    const query = useQuery({
        queryKey: ['jobOffers'],
        queryFn: getJobOffers,
    });

    const offer = useMemo(
        () => (query.isSuccess ? query.data.find((offer) => offer.id.toString() === id) : null),
        [query.isSuccess, query.data, id],
    );

    const handleShowApplicationForm = () => setShowApplicationForm(true);

    const handleCloseApplicationForm = () => setShowApplicationForm(false);

    return (
        <div className="">
            {offer && (
                <div className="">
                    <div className="mx-auto px-5 text-justify md:w-3/4 lg:w-2/3 xl:w-1/2">
                        <div className="flex">
                            <Link to="/recruiting">&lt; Retourner aux offres</Link>
                        </div>
                        <div className="flex items-center justify-between">
                            <div>
                                <h2 className="font-weight-light ml-0 pb-2 text-left">
                                    {offer.title} ({offer.type})
                                </h2>
                                <h6 className="pb-2 text-left">{offer.address}</h6>
                                <h6>{offer.salary}</h6>
                            </div>
                            <div className="">
                                <Button variant="dark" onClick={handleShowApplicationForm}>
                                    Postuler
                                </Button>
                            </div>
                        </div>
                        <div className="m-4">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: convertRawStateToHtml(offer.description),
                                }}
                            />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: convertRawStateToHtml(offer.required_experiences),
                                }}
                            />
                        </div>
                        <Row className="m-5">
                            <Button variant="dark" className="mx-auto" onClick={handleShowApplicationForm}>
                                Postuler
                            </Button>
                        </Row>
                    </div>
                </div>
            )}
            {showApplicationForm && (
                <div className="">
                    <Modal
                        size="lg"
                        show={showApplicationForm}
                        onHide={handleCloseApplicationForm}
                        className="border-none bg-transparent"
                        dialogClassName="border-none bg-transparent"
                        contentClassName="border-none bg-transparent"
                    >
                        <Modal.Body className="position-relative border-none bg-transparent p-0">
                            {offer && (
                                <ApplicationForm
                                    offer={offer}
                                    className="m-auto bg-transparent"
                                    onSuccess={() => window.setTimeout(() => handleCloseApplicationForm(), 1500)}
                                />
                            )}
                            <Button
                                className="close text-light position-absolute"
                                data-dismiss="modal"
                                aria-label="Close"
                                style={{ top: '1rem', right: '1rem', zIndex: 2048, opacity: 1 }}
                                onClick={handleCloseApplicationForm}
                            >
                                &times;
                            </Button>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
}
