import { animated, useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';

export default function ScrollToTopButton() {
    const [isVisible, setIsVisible] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const springs = useSpring({
        transform: isHovered ? `scale(1.05)` : isVisible ? `scale(1)` : `scale(0)`,
        config: {
            tension: 300,
            friction: 15,
        },
    });

    return (
        <>
            {isVisible && (
                <animated.button
                    onClick={scrollToTop}
                    style={{ ...springs }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    className="border-0 rounded-xl bg-slate-200/90 p-5"
                >
                    <div className="flex w-full flex-row items-center">
                        <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="inline h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
                                />
                            </svg>
                        </span>
                        <span className="inline whitespace-nowrap">retourner en haut</span>
                    </div>
                </animated.button>
            )}
        </>
    );
}
