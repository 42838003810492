import { Image } from 'react-bootstrap';

import Plan from './Images/plan.png';
import './index.css';

export type SiteLink = {
    name: string;
    content?: JSX.Element;
    href?: string;
    target?: string;
    rel?: string;
};

export type FooterCategory = {
    title: string;
    links: SiteLink[];
};

export type FooterSection = {
    title: string;
    categories: FooterCategory[];
};

const sections: FooterSection[] = [
    {
        title: 'Plan du site',
        categories: [
            {
                title: 'Qui sommes-nous',
                links: [
                    { name: 'Notre métier', href: '/job' },
                    { name: 'Notre histoire', href: '/history' },
                ],
            },
            {
                title: 'Devenir un Insider',
                links: [
                    { name: "Qu'est-ce qu'un Insider", href: '/insider' },
                    { name: 'Comment devenir un Insider', href: '/recruiting' },
                ],
            },
            {
                title: 'Actualités',
                links: [
                    { name: 'Événements', href: '/events' },
                    { name: 'Le Blog', href: '/blog' },
                ],
            },
        ],
    },
    {
        title: 'Contact',
        categories: [
            {
                title: 'Adresse',
                links: [
                    {
                        name: 'Voir sur Google Maps',
                        content: (
                            <>
                                14 Pl. de la Coupole
                                <br />
                                94 220 Charenton-Le-Pont - France
                            </>
                        ),
                        href: 'https://maps.app.goo.gl/WJETx3JvpeegjVdi8',
                        target: '_blank',
                    },
                ],
            },
            {
                title: 'Téléphone',
                links: [{ name: '+33 1 40 57 64 20' }],
            },
            {
                title: 'Couriel',
                links: [{ name: 'contact@codeinsider.fr' }],
            },
        ],
    },
];

export default function SiteMap() {
    return (
        <div className="mx-auto flex w-11/12 flex-col flex-wrap content-center items-center justify-around gap-x-20 gap-y-5 py-5 md:flex-row">
            <div className="grow">
                {sections.map((section) => (
                    <div key={section.title}>
                        <h3 className="text-left">{section.title}</h3>
                        <div className="flex flex-wrap gap-x-10 gap-y-0">
                            {section.categories.map((category) => (
                                <div className="mx-4 md:flex-1" key={category.title}>
                                    <h4 className="text-left">{category.title}</h4>
                                    <ul className="p-0">
                                        {category.links.map((link) => (
                                            <li key={link.name} className="list-none px-0 text-left">
                                                <a
                                                    className="translate-0 text-black no-underline transition-colors  hover:text-[#efac3d]"
                                                    key={link.name}
                                                    href={link.href}
                                                    target={link.target}
                                                    rel={link.rel}
                                                >
                                                    {link.content ?? link.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <Image className="pt-0 md:pt-8" src={Plan} alt="Site map" height="250" />
        </div>
    );
}
