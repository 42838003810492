/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SpotifyAPIWebItemType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type SpotifyAPIWebItemType = typeof SpotifyAPIWebItemType[keyof typeof SpotifyAPIWebItemType];


export function SpotifyAPIWebItemTypeFromJSON(json: any): SpotifyAPIWebItemType {
    return SpotifyAPIWebItemTypeFromJSONTyped(json, false);
}

export function SpotifyAPIWebItemTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpotifyAPIWebItemType {
    return json as SpotifyAPIWebItemType;
}

export function SpotifyAPIWebItemTypeToJSON(value?: SpotifyAPIWebItemType | null): any {
    return value as any;
}

