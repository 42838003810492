import './index.css';

export type HeaderProps = {
    title: string;
    subTitle?: string;
    image?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function Header({ title, subTitle, image, className, ...props }: HeaderProps) {
    return (
        <div className={`header ${className}`} style={{ backgroundImage: `url(${image})` }} {...props}>
            <div className="headerText">
                <h1>{title}</h1>
                <h2>{subTitle}</h2>
            </div>
        </div>
    );
}
