import { Image } from 'react-bootstrap';

import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import Button from '../../Button';
import Header from '../../Header';
import TriangleContainer from '../../TriangleContainer';
import Badge from './Images/badge.png';
import Ben from './Images/benjamin.png';
import Ces from './Images/cesar.png';
import Customers from './Images/first-customers.png';
import HeaderImage from './Images/header.png';
import Insider from './Images/insider.png';
import Toolbox from './Images/toolbox-tech.png';
import ValuesSection from './ValuesSection';
import './index.css';

export default function PageHistory() {
    useFormattedDocumentTitle('La genèse');

    return (
        <>
            <Header image={HeaderImage} title="La genèse" subTitle="de Code Insider" />
            <div style={{ width: '100%', height: 'auto', display: 'block', overflow: 'hidden' }}>
                <h3 className="text-centered" style={{ padding: '2em 0' }}>
                    Benjamin et César, les deux fondateurs, sont amis de longues dates ! Alors que Benjamin est un
                    ancien développeur .NET, obsédé par la qualité de code, César, quant à lui, dispose d'un parcours de
                    manager, et est fondamentalement drivé par l'humain et le bien être au travail !
                </h3>
                <div className="splitContainer">
                    <div style={{ width: '100%' }}>
                        <Image className="imageSizingSplit textSizingSplit" src={Ben} alt="Benjamin Vidon" />
                        <h4>Benjamin Vidon</h4>
                        <p className="textSizingSplit text-centered">
                            Double diplôme : Ingénieur (ISEP, Paris),
                            <br />
                            Management (Cranfield University, UK)
                        </p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <Image className="imageSizingSplit textSizingSplit" src={Ces} alt="César Mourot" />
                        <h4>César Mourot</h4>
                        <p className="textSizingSplit text-centered">
                            Management, Économie, et Gestion (La Sorbonne, Paris)
                        </p>
                    </div>
                </div>
                <p className="margin-top textSizing text-centered" style={{ paddingBottom: '10em', paddingTop: '2em' }}>
                    Benjamin démarre sa carrière à la SG CIB (Société Générale Corporate & Investment Banking) en tant
                    que développeur .NET.
                    <br />
                    Très rapidement, il prend conscience de l’importance de la qualité de code, et des méfaits du «
                    quick & dirty », qui sont les causes principales de la dette technique, et du code spaghetti non
                    testable.
                    <br />
                    Après un passage au sein d’une ESN généraliste où ils ont pu travailler ensemble, Benjamin et César
                    décident de fonder une structure dont l’ADN est le Software Craftsmanship, et la qualité de façon
                    générale.
                    <br />
                    Alors que Benjamin est drivé par la qualité de code, César, lui, pousse l’entreprise à adopter des
                    méthodologies de management les plus modernes et pragmatiques possibles, afin de promouvoir le
                    bien-être au travail.
                </p>
            </div>
            <TriangleContainer>
                <div className="left-column">
                    <div className="text-center">
                        <Image className="imageSizing" id="badge" src={Badge} alt="Emblème" />
                        <p className="textSizingSplit text-justify">
                            <strong>20 janvier 2015</strong> : Code Insider est née, et commence à rechercher ses
                            premiers joailliers du code ! Test first, Clean Code et Software Craftsmanship sont les
                            drivers de l'entreprise !
                        </p>
                    </div>
                    <div className="historyMarginTop text-center">
                        <Image className="imageSizing" id="toolbox" src={Toolbox} alt="Toolbox" />
                        <h2>Boîte à outils</h2>
                        <p className="textSizingSplit text-justify">
                            Parce que le métier de développeur en ESN est compliqué de part le détachement du
                            collaborateur chez le client, Code Insider décide de proposer des solutions disruptives pour
                            renforcer le sentiment d'appartenance à l'entreprise de ses développeurs : katas, dojos,
                            jams de code, hackathons, meet-ups, certifications, conférences, projets de Recherche et
                            Développement, MOOC, formations (internes et externes)…
                            <br />
                            Tout est mis en place pour que nos Insiders se retrouvent le plus possible autour de sujets
                            techniques, afin de parfaire leur compétences tout au long de leur carrière chez Code
                            Insider. Et pour que tous nos Insiders se connaissent entre eux, un réseau social
                            d'entreprise a été mis en place !
                        </p>
                    </div>
                </div>
                <div className="right-column">
                    <div className="text-center">
                        <h2>Nos premiers clients</h2>
                        <p className="textSizingSplit text-justify">
                            Très rapidement, de nombreux clients grand compte font confiance à Code Insider au vu de la
                            qualité de ses collaborateurs. La société s'affranchit des référencements, et très
                            rapidement, propose à ses développeurs les meilleures missions de la place parisienne !
                        </p>
                        <Image className="imageSizing" id="skycrapers" src={Customers} alt="Premiers clients" />
                    </div>
                    <div className="historyMarginTop historyMarginBottom">
                        <h1>Code Insider est une référence sur le Software Craftsmanship en France !</h1>
                    </div>
                </div>
                <Button variant="light" link="/job">
                    Notre métier
                </Button>
                <div className="img-back">
                    <Image id="imageInsider" className="imageSizing" src={Insider} alt="Insider" />
                </div>
                <ValuesSection />
            </TriangleContainer>
        </>
    );
}
