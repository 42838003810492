import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { type Options as DraftOptions } from 'draft-js-export-html';

export const exportOptions: DraftOptions = {
    inlineStyles: {
        COLOR_RED: {
            style: {
                color: 'red',
            },
        },
        COLOR_GREEN: {
            style: {
                color: 'green',
            },
        },
        COLOR_BLUE: {
            style: {
                color: '#014f9b',
            },
        },
    },
    blockStyleFn: (block) => {
        const type = block.getType();

        if (type === 'header-one') return { attributes: { class: 'intraEditorTitle' } };
        else if (type === 'right') return { attributes: { class: 'intraEditorAlignRight' } };
        else if (type === 'center') return { attributes: { class: 'intraEditorAlignCenter' } };
        else if (type === 'header-two') return { attributes: { class: 'intraEditorTitle2' } };
    },
};

export function convertRawStateToHtml(json: string) {
    const rawState = JSON.parse(json);
    const state = convertFromRaw(rawState);
    return stateToHTML(state, exportOptions);
}
