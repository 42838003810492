import { Row } from 'react-bootstrap';
import Button from '../../../../Components/Button';

export function JobOfferList() {

    return (
        <div style={{ paddingBottom: '3em', paddingLeft: '1em', paddingRight: '1em' }}>
            <br />
            <Row>
                <h3 className="w-100 col-auto mx-auto">
                    Pour accéder à l'ensemble de nos offres d'emploi ou pour nous envoyer une candidature spontanée, cliquez sur ce bouton : <br /><br />
                    <a style={{ textDecoration: "none" }} href="https://www.recrutement.bluesoft-group.com/offres-d-emploi-blue-soft/?location=all&contract_type=all&job_bu=61&job_expertise=all" target="_blank">
                        <Button>Voir toutes les offres</Button>
                    </a>
                </h3>
            </Row>
        </div>
    );
}
