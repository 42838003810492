import { useInfiniteQuery } from '@tanstack/react-query';
import Lottie from 'lottie-react';

import { SpotifyApi } from '../../../api';
import banner1 from '../../../assets/images/banner1.png';
import rocketAnimation from '../../../assets/lottie/lottie-rocket.json';
import useApi from '../../../hooks/useApi';
import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import { podcasts } from '../../../socialMedias';
import { EmbedSpotifyEpisode } from '../../Embeds/spotify';
import Header from '../../Header';
import { BlogMesonryTile } from '../../Social/MesonryTiles/BlogMesonryTile';
import { TwitterMesonryTile } from '../../Social/MesonryTiles/TwitterMesonryTile';
import { VideosMesonryTile } from '../../Social/MesonryTiles/VideosMesonryTile';
import { SocialLink } from '../../Social/SocialLink';
import { SocialMediaSidebarContainer } from '../../Social/SocialMediaSidebarContainer';

export default function PodcastsPage() {
    useFormattedDocumentTitle('Podcasts');
    const spotifyApi = useApi(SpotifyApi);

    const pageSize = 10;

    const query = useInfiniteQuery({
        queryKey: ['spotify', 'episodes'],
        queryFn: ({ pageParam = 1 }) =>
            spotifyApi.getSpotifyEpisodes({ limit: pageSize, offset: (pageParam - 1) * pageSize }),
        getNextPageParam: (res, pages) => (res.next ? pages.length + 1 : null),
        getPreviousPageParam: (res, pages) => (res.previous ? pages.length - 1 : null),
    });

    return (
        <div className="mb-10">
            <Header
                title="Podcasts"
                subTitle="Mieux que les livres audio amazon"
                image={banner1}
                className="bg-center"
            />
            <div className="mx-auto mb-16 w-full p-3 md:w-5/6">
                <div className="flex flex-col gap-3 lg:flex-row lg:items-start">
                    <div className="w-full">
                        <div className="mt-3 grid w-full grid-cols-1 gap-3 lg:grid-cols-2">
                            {query.data?.pages.map((page) =>
                                page?.items?.map((episode) => (
                                    <div key={episode.id}>
                                        {episode.id && <EmbedSpotifyEpisode episodeId={episode.id} width="100%" />}
                                    </div>
                                )),
                            )}
                        </div>
                        {query.hasNextPage && (
                            <div className="mt-3 flex w-full justify-center">
                                <button
                                    className="rounded-2xl border-none bg-blue-200 p-3"
                                    onClick={() => query.fetchNextPage()}
                                >
                                    montrer plus
                                </button>
                            </div>
                        )}
                        {query.isLoading && (
                            <div className="flex justify-center">
                                <Lottie animationData={rocketAnimation} loop={true} className="h-1/6 w-1/6" />
                            </div>
                        )}
                        <div className="my-5">
                            <h4>Toutes nos plateformes de podcasts</h4>
                            <div className="flex flex-col gap-5 lg:flex-row">
                                {podcasts.map((network) => (
                                    <SocialLink
                                        key={network.name}
                                        icon={network.icon}
                                        name={network.name}
                                        href={network.href}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className="block lg:hidden">Découvrez aussi</h2>
                        <SocialMediaSidebarContainer>
                            <BlogMesonryTile count={1} withLink />
                            <VideosMesonryTile count={1} withLink />
                            <TwitterMesonryTile height={400} />
                        </SocialMediaSidebarContainer>
                    </div>
                    {/* <SocialMediaSidebar hidePodcasts={true} /> */}
                </div>
            </div>

            {/* <div className="flex flex-col items-center">
                <button
                    className="rounded-full border-none bg-blue-200 px-5 py-3 text-blue-600"
                    // onClick={() => query.fetchNextPage()}
                >
                    Charger plus
                </button>
                <Lottie animationData={rocketAnimation} loop={true} className="h-1/6 w-1/6" />
            </div> */}

            {/* <div className="flex flex-col items-center">
                {query.hasNextPage && (
                    <button
                        className="rounded-full border-none bg-blue-300 text-blue-600"
                        onClick={() => query.fetchNextPage()}
                    >
                        Load More
                    </button>
                )}
                {query.isFetchingNextPage && <div>Loading...</div>}
            </div> */}
        </div>
    );
}
