export const BASE_URL = 'https://codeinsider-wp.azurewebsites.net/wp-json/wp/v2';

export type Rendered = {
    rendered: string;
};

export type WordpressPost = {
    id: number;
    date: string;
    date_gmt: string;
    slug: string;
    guid: Rendered;
    modified: string;
    link: string;
    title: Rendered;
    content: Rendered;
    excerpt: Rendered;
    author: number;
    jetpack_featured_media_url: string;
    _embedded?: {
        'wp:featuredmedia': Array<{
            source_url: string;
        }>;
        author: Array<{
            id: number;
            name: string;
            url: string;
            description: string;
            link: string;
            slug: string;
            avatar_urls: {
                24: string;
                48: string;
                96: string;
            };
            _links: {
                self: Array<{
                    href: string;
                }>;
                collection: Array<{
                    href: string;
                }>;
            };
        }>;
    };
};

export type WordpressCategory = {
    id: number;
    count: number;
    description: string;
    link: string;
    name: string;
    slug: string;
    taxonomy: string;
    parent: string;
    meta: string;
    _links: string;
};

export type WordpressTag = {
    id: number;
    count: number;
    description: string;
    link: string;
    name: string;
    slug: string;
    taxonomy: string;
    meta: string;
    _links: string;
};

export type WordpressUser = {
    id: number;
    name: string;
    url: string;
    description: string;
    link: string;
    slug: string;
    avatar_urls: string;
    meta: string;
    _links: string;
};

export type GetPostsResult = {
    items: WordpressPost[];
    headers: Headers;
    totalPages?: number;
    totalItems?: number;
    page: number;
    perPage: number;
};

export default async function getPosts({ page = 1, perPage = 10 }): Promise<GetPostsResult> {
    return fetch(`${BASE_URL}/posts?_embed=true&per_page=${perPage}&page=${page}`).then(async (res) => {
        return {
            items: (await res.json()) as WordpressPost[],
            totalPages: parseInt(res.headers.get('X-WP-TotalPages') || '0', 10),
            totalItems: parseInt(res.headers.get('X-WP-Total') || '0', 10),
            headers: res.headers,
            page,
            perPage,
        };
    });
}

export async function getPostById(postId: number): Promise<WordpressPost> {
    return fetch(`${BASE_URL}/posts/${postId}`).then((res) => res.json() as Promise<WordpressPost>);
}

export async function getPostBySlug(postSlug: string): Promise<WordpressPost[]> {
    return fetch(`${BASE_URL}/posts?_embed=true&slug=${postSlug}`).then(
        (res) => res.json() as Promise<WordpressPost[]>,
    );
}

export async function getCategories(): Promise<WordpressCategory[]> {
    return fetch(`${BASE_URL}/categories`).then(async (res) => res.json());
}

export async function getTags(): Promise<WordpressTag[]> {
    return fetch(`${BASE_URL}/tags`).then(async (res) => res.json());
}

export async function getUsers(): Promise<WordpressUser[]> {
    return fetch(`${BASE_URL}/users`).then(async (res) => res.json());
}

export async function getUserById({ userId }: { userId: number }): Promise<WordpressUser> {
    return fetch(`${BASE_URL}/users/${userId}`).then((res) => res.json() as Promise<WordpressUser>);
}
