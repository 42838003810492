import { WordpressPost } from '../../Apis/wordpress';
import SanitizeHTML from '../../util/SanitizeHTML';

export function WordpressPreview({ article }: { article: WordpressPost }) {
    return (
        <div key={article.id} className="d-flex mb-3 flex-col flex-wrap md:flex-row">
            <div className="ms-4 w-full flex-shrink-0">
                <a href={`/blog/${article.slug}`}>
                    <img
                        width="100%"
                        className="align-self-start media-img rounded shadow"
                        src={
                            article.jetpack_featured_media_url ??
                            article._embedded?.['wp:featuredmedia']?.[0]?.source_url ??
                            '#'
                        }
                        alt=""
                    />
                </a>
            </div>
            <div className="flex-grow-1 ml-4">
                <a className="text-dark" href={`/blog/${article.slug}`}>
                    <h5 className="mt-4 text-2xl">
                        <SanitizeHTML html={article.title?.rendered ?? null} />
                    </h5>
                </a>
                <div className="text-lg">
                    <SanitizeHTML html={article.excerpt?.rendered ?? null} />
                </div>
                <a
                    className="text-arrow-icon flex items-center"
                    href={`/blog/${article.slug}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <span className="text-lg">Aller vers l'article</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-right"
                    >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                </a>
            </div>
        </div>
    );
}

export function WordpressHightlight({ article }: { article: WordpressPost }) {
    return (
        <div key={article.id} className="d-flex my-3 flex-col gap-5 md:flex-row">
            {(article.jetpack_featured_media_url || article._embedded?.['wp:featuredmedia']?.[0]?.source_url) && (
                <div className="ms-4 w-full md:max-h-[300px] md:w-[300px]">
                    <a href={`/blog/${article.slug}`}>
                        <img
                            className="align-self-start media-img w-full rounded object-cover shadow md:max-h-[300px] md:w-[300px]"
                            src={
                                article.jetpack_featured_media_url ??
                                article._embedded?.['wp:featuredmedia']?.[0]?.source_url ??
                                ''
                            }
                            alt="..."
                        />
                    </a>
                </div>
            )}

            <div className="flex-grow-1 mx-4 mt-1">
                <a className="text-dark" href={`/blog/${article.slug}`}>
                    <h5 className="text-3xl">
                        <SanitizeHTML html={article.title?.rendered ?? ''} />
                    </h5>
                </a>
                <div className="text-xl">
                    <SanitizeHTML html={article.excerpt?.rendered ?? ''} />
                </div>
                <a
                    className="text-arrow-icon flex items-center"
                    href={`blog/${article.slug}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <span className="text-lg">Aller vers l'article</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-right"
                    >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                </a>
            </div>
        </div>
    );
}
