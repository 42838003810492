/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GoogleApisYouTubeV3DataThumbnail
 */
export interface GoogleApisYouTubeV3DataThumbnail {
    /**
     * 
     * @type {number}
     * @memberof GoogleApisYouTubeV3DataThumbnail
     */
    height?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataThumbnail
     */
    url?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoogleApisYouTubeV3DataThumbnail
     */
    width?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataThumbnail
     */
    eTag?: string | null;
}

/**
 * Check if a given object implements the GoogleApisYouTubeV3DataThumbnail interface.
 */
export function instanceOfGoogleApisYouTubeV3DataThumbnail(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GoogleApisYouTubeV3DataThumbnailFromJSON(json: any): GoogleApisYouTubeV3DataThumbnail {
    return GoogleApisYouTubeV3DataThumbnailFromJSONTyped(json, false);
}

export function GoogleApisYouTubeV3DataThumbnailFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleApisYouTubeV3DataThumbnail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'height': !exists(json, 'height') ? undefined : json['height'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'eTag': !exists(json, 'eTag') ? undefined : json['eTag'],
    };
}

export function GoogleApisYouTubeV3DataThumbnailToJSON(value?: GoogleApisYouTubeV3DataThumbnail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'height': value.height,
        'url': value.url,
        'width': value.width,
        'eTag': value.eTag,
    };
}

