/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpotifyAPIWebResumePoint
 */
export interface SpotifyAPIWebResumePoint {
    /**
     * 
     * @type {boolean}
     * @memberof SpotifyAPIWebResumePoint
     */
    fullyPlayed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SpotifyAPIWebResumePoint
     */
    resumePositionMs?: number;
}

/**
 * Check if a given object implements the SpotifyAPIWebResumePoint interface.
 */
export function instanceOfSpotifyAPIWebResumePoint(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SpotifyAPIWebResumePointFromJSON(json: any): SpotifyAPIWebResumePoint {
    return SpotifyAPIWebResumePointFromJSONTyped(json, false);
}

export function SpotifyAPIWebResumePointFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpotifyAPIWebResumePoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullyPlayed': !exists(json, 'fullyPlayed') ? undefined : json['fullyPlayed'],
        'resumePositionMs': !exists(json, 'resumePositionMs') ? undefined : json['resumePositionMs'],
    };
}

export function SpotifyAPIWebResumePointToJSON(value?: SpotifyAPIWebResumePoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullyPlayed': value.fullyPlayed,
        'resumePositionMs': value.resumePositionMs,
    };
}

