import { useQuery } from '@tanstack/react-query';
import Lottie from 'lottie-react';
import moment from 'moment';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

// import { WordpressApi } from '../../../api/apis/WordpressApi';
// import useApi from '../../../hooks/useApi';
import { getPostBySlug, getUserById } from '../../../Apis/wordpress';
import rocketAnimation from '../../../assets/lottie/lottie-rocket.json';
import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import SanitizeHTML from '../../../util/SanitizeHTML';
import stripHtml from '../../../util/stripHtml';
import { ReturnToArticlesLink } from './ReturnToArticlesLink';
import ScrollToTopButton from './ScrollToTopButton';
import './index.css';

export default function BlogItemPage() {
    const { id: postSlug } = useParams<{ id: string }>();

    // const postId = useMemo(() => (!!id ? parseInt(id) : null), [id]);

    const wordpressQuery = useQuery({
        queryKey: ['wordpress', postSlug],
        queryFn: () => (postSlug ? getPostBySlug(postSlug) : null),
        enabled: !!postSlug,
    });

    const found = useMemo(() => wordpressQuery.data?.at(0), [wordpressQuery.data]);

    useFormattedDocumentTitle(found?.title?.rendered ? stripHtml(found?.title.rendered) : 'Blog');

    return (
        <div className="mx-auto mb-10 w-full p-5 md:w-2/3">
            <div className="flex pt-3">{ReturnToArticlesLink()}</div>
            <div>
                {wordpressQuery.isLoading && (
                    <div className="flex items-center justify-center">
                        <Lottie animationData={rocketAnimation} loop={true} width={'25%'} height={'50%'} />
                    </div>
                )}
                {wordpressQuery.isSuccess && (
                    <div className="blog-container">
                        <div className="pb-10">
                            <h1 className="pt-8 pb-0 font-bold">
                                <SanitizeHTML html={found?.title?.rendered ?? ''} />
                            </h1>
                            <p className="text-center text-lg text-neutral-500">
                                <span>
                                    {(wordpressQuery.data?.at(0)?._embedded?.author && (
                                        <>
                                            Par{' '}
                                            <span className="font-bold">
                                                {wordpressQuery.data?.at(0)?._embedded?.author?.at(0)?.name}
                                            </span>
                                            {' le '}
                                        </>
                                    )) || <>{' Le '}</>}
                                </span>{' '}
                                <span className="font-bold">{moment(found?.date).format('LL')}</span>, mis à jour le{' '}
                                <span className="font-bold">{moment(found?.modified).format('LL')}</span>
                            </p>
                        </div>

                        <div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: found?.content?.rendered ? found?.content.rendered : '',
                                }}
                            />
                            <div className="sticky bottom-0">
                                <div className="relative bottom-0 right-0 left-auto p-3 text-right lg:absolute lg:right-auto lg:left-full">
                                    <ScrollToTopButton />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
