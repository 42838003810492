export type OnlineResourceItem = {
    name: string;
    url: string;
    description?: string;
    image?: string;
    tags?: string[];
    price?: string;
    duration?: string;
    language?: 'french' | 'english' | 'multilingual' | 'other';
    level?: 'beginner' | 'intermediate' | 'advanced';
    medium?: 'video' | 'article' | 'book' | 'podcast' | 'course' | 'game' | 'other';
    isCodeInsider?: boolean;
};

export const onlineResources: OnlineResourceItem[] = [
    {
        name: 'Exercism',
        url: 'https://exercism.io/',
        description: 'Practice programming by solving exercises',
        tags: ['practice', 'programming', 'exercises'],
        price: 'free',
    },

    {
        url: 'https://learnxinyminutes.com/',
        name: 'Learn X in Y minutes',
        description: 'Learn a programming language in a few minutes',
        tags: ['programming', 'exercises'],
        price: 'free',
        level: 'beginner',
        medium: 'article',
        language: 'multilingual',
    },

    {
        url: 'https://www.udemy.com/course/maitriser-web-api-rest-avec-aspnet-core-dotnet-50-full/?kw=ASP.NET+Core&src=sac',
        name: 'Udemy : Maitriser Web API Rest avec ASP.NET Core',
        description: 'Learn how to build a REST API with ASP.NET Core',
        tags: ['api', 'rest', 'asp.net core'],
        language: 'french',
    },

    {
        url: 'https://www.udemy.com/course/typescript-pour-tous/?kw=TypeScript&src=sac',
        name: 'Udemy : TypeScript pour tous',
        description: 'Learn TypeScript',
        tags: ['typescript'],
        language: 'french',
    },

    {
        url: 'https://www.udemy.com/course/react-js-redux-guide-du-debutant/',
        name: 'Udemy : React JS & Redux - Guide du Débutant',
        description: 'Learn React JS and Redux',
        tags: ['react', 'redux'],
        language: 'french',
    },

    {
        url: 'https://kata-log.rocks/starter',
        name: 'Kata Log (Starter)',
        description: 'Learn how to solve programming problems',
        tags: ['practice', 'programming', 'exercises'],
        language: 'french',
    },

    {
        url: 'https://github.com/joebew42/study-path',
        name: 'Study Path',
        description: 'A curated list of resources to learn programming',
        tags: ['programming', 'resources'],
        language: 'english',
    },

    {
        name: 'CodeWars',
        url: 'https://www.codewars.com/',
        description: 'Practice programming by solving exercises',
        tags: ['practice', 'programming', 'exercises'],
        price: 'free',
    },

    {
        url: 'https://blog.codeinsider.fr/metal-gear-s-o-l-i-d/',
        name: 'Les principes S.O.L.I.D. illustrés',
        description: 'Apprenez à appliquer les principes S.O.L.I.D. à votre code',
        tags: ['solid', 'principles', 'programming'],
        language: 'french',
        isCodeInsider: true,
    },

    {
        url: 'https://blog.codeinsider.fr/le-pair-programming-quand-les-deux-font-la-paire/',
        name: "Le pair programming : la solution par l'entraide",
        description: 'Apprenez à utiliser le pair programming pour améliorer votre code',
        tags: ['pair programming', 'programming'],
        isCodeInsider: true,
    },

    {
        url: 'https://blog.codeinsider.fr/tdd-test-driven-development-idees-recues-avantages-montee-en-competence/',
        name: 'TDD : Test Driven Development',
        description: 'Apprenez à utiliser le TDD pour améliorer votre code',
        tags: ['tdd', 'programming'],
        isCodeInsider: true,
    },

    {
        url: 'https://blog.codeinsider.fr/le-bdd-levidence-de-la-collaboration/',
        name: 'BDD : Behavior Driven Development',
        description: 'Apprenez à utiliser le BDD pour améliorer votre code',
        tags: ['bdd', 'programming'],
        language: 'french',
        medium: 'article',
        isCodeInsider: true,
    },

    {
        url: 'https://www.youtube.com/watch?v=nbSaq_ykOl4',
        name: "Test-Driven Development (TDD) par l'exemple, et en français !",
        description: 'Apprenez à utiliser le TDD pour améliorer votre code',
        tags: ['tdd', 'programming'],
        language: 'french',
        medium: 'video',
        isCodeInsider: true,
    },

    {
        url: 'https://www.youtube.com/watch?v=btDVH1yMI8c',
        name: ' Comment apprendre à faire du TypeScript en TDD ?',
        description: 'Apprenez à utiliser le TDD pour améliorer votre code',
        tags: ['tdd', 'programming'],
        language: 'french',
        medium: 'video',
        isCodeInsider: true,
    },
    {
        url: 'https://www.youtube.com/watch?v=jPmYjvVa9_c',
        name: 'Implement BDD with Specflow !',
        description: 'Apprenez à utiliser le BDD pour améliorer votre code',
        tags: ['bdd', 'programming'],
        language: 'english',
        medium: 'video',
        isCodeInsider: true,
    },
];
