/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GoogleApisYouTubeV3DataResourceId
 */
export interface GoogleApisYouTubeV3DataResourceId {
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataResourceId
     */
    channelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataResourceId
     */
    kind?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataResourceId
     */
    playlistId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataResourceId
     */
    videoId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataResourceId
     */
    eTag?: string | null;
}

/**
 * Check if a given object implements the GoogleApisYouTubeV3DataResourceId interface.
 */
export function instanceOfGoogleApisYouTubeV3DataResourceId(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GoogleApisYouTubeV3DataResourceIdFromJSON(json: any): GoogleApisYouTubeV3DataResourceId {
    return GoogleApisYouTubeV3DataResourceIdFromJSONTyped(json, false);
}

export function GoogleApisYouTubeV3DataResourceIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleApisYouTubeV3DataResourceId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'kind': !exists(json, 'kind') ? undefined : json['kind'],
        'playlistId': !exists(json, 'playlistId') ? undefined : json['playlistId'],
        'videoId': !exists(json, 'videoId') ? undefined : json['videoId'],
        'eTag': !exists(json, 'eTag') ? undefined : json['eTag'],
    };
}

export function GoogleApisYouTubeV3DataResourceIdToJSON(value?: GoogleApisYouTubeV3DataResourceId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channelId': value.channelId,
        'kind': value.kind,
        'playlistId': value.playlistId,
        'videoId': value.videoId,
        'eTag': value.eTag,
    };
}

