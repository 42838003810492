import { SocialMediaMosaicContainer } from './SocialMediaMosaicContainer';

export function SocialMediaSidebarContainer({ children }: { children: React.ReactNode }) {
    return (
        <div className="flex flex-col gap-2 lg:w-96">
            <SocialMediaMosaicContainer
                columnsCountBreakPoints={{
                    0: 1,
                    768: 2,
                    1024: 1,
                }}
            >
                {children}
            </SocialMediaMosaicContainer>
        </div>
    );
}
