import React from 'react';
import { Image } from 'react-bootstrap';

import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import Button from '../../Button';
import Header from '../../Header';
import TriangleContainer from '../../TriangleContainer';
import Compasses from './Images/compasses.png';
import Diamond from './Images/diamond.png';
import HeaderImage from './Images/header.png';
import Team from './Images/team.png';
import './index.css';

export default function PageJob() {
    useFormattedDocumentTitle('Notre métier');

    return (
        <>
            <Header title="NOTRE METIER :" subTitle="CRAFTS(WO)MANSHIP" image={HeaderImage} />
            <div>
                <p className="textSizing textSmallSize text-justify">
                    Pour bien comprendre l’intérêt du Software Craftsmanship, il n’est pas inutile de prendre comme
                    exemple le logiciel Louvois, conçu pour traiter les paies de l’armée, et rendu célèbre pour ses
                    défaillances (salaires erronés et retards de paie de plusieurs mois).
                    <br />
                    <br />
                    Ce logiciel a couté à l’État français plusieurs centaines de millions d’Euros. Il a été trop vite et
                    mal conçu, avec une série impressionnante de bugs qui ont dû être corrigés manuellement un à un. Un
                    audit interne de 2010 constate que « Louvois est peu robuste, difficilement maintenable et
                    exploitable ».
                    <br />
                    <br />
                    Rétrospectivement ce logiciel – au-delà du contexte particulièrement sensible – aurait dû être
                    construit avec des méthodes et des outils permettant de garantir pérennité, maintenabilité du code
                    et testabilité pour assurer une utilisation robuste.
                </p>
            </div>
            <div className="bg-light">
                <div style={{ paddingBottom: '10em' }}>
                    <h1>Notre vision du Software Craftsmanship</h1>
                    <p className="textSizing textMediumSize text-justify">
                        Le Software Craftsmanship est une approche de développement logiciel qui met l’accent sur la
                        qualité afin de répondre aux problématiques rencontrées par les développeurs. Il ne suffit pas
                        de rendre un logiciel fonctionnel. Il faut surtout qu’il soit bien conçu.
                        <br />
                        <br />
                        Au-delà de l’agilité qui permet une souplesse des cycles de développements, le Crafts met
                        l’accent sur la qualité du code et la manière dont il est écrit. Pour cela, il existe un certain
                        nombre de méthodologies telles que le TDD (Test-Driven Development) qui permet un développement
                        piloté par tests et qui garantit une meilleure qualité de code avec son fameux cycle (Red,
                        Green, Refactor). D’autres principes peuvent être appliqués comme SOLID, KISS ou DRY pour
                        garantir une meilleure maintenabilité du code.
                        <br />
                        <br />
                        Un autre principe essentiel est la propriété collective du code car plus le nombre de
                        développeurs qui y travaillent est important plus le risque de bugs est grand. Les revues de
                        code et le Pair Programming, aident à prévenir les bugs et à garantir la qualité du code.
                    </p>
                </div>
            </div>
            <TriangleContainer behind>
                <div className="splitContainer3" style={{ paddingBottom: '2em' }}>
                    <div className="divSizing3">
                        <Image className="imageSizingSplit3" src={Compasses} alt="Compas" />
                        <h2>Culte de la bonne pratique</h2>
                        <p className="textSizingSplit3 text-justify">
                            "Le problème avec le 'vite et sale', c'est que le 'sale' demeure bien longtemps après que le
                            'vite' ait été oublié" (Steve McConnell)
                            <br />
                            Chez Code insider, nos développeurs banissent le "quick&dirty", et produisent des systèmes
                            de haute qualité, de par leur niveau technique très solide, des méthodologies innovantes, et
                            les techniques les plus avancées de développement logiciel.
                        </p>
                    </div>
                    <div className="divSizing3">
                        <Image className="imageSizingSplit3" src={Diamond} alt="Diamand" />
                        <h2>Le consultant, notre épicentre</h2>
                        <p className="textSizingSplit3 text-justify">
                            Parce que la valeur d'une entreprise ne se mesure que par les hommes et les femmes qui la
                            composent, seul un développeur épanoui pourra fournir du bon code.
                            <br />
                            Le bien-être de nos consultants est donc notre priorité. Et concrètement ? Des projets et
                            missions sur-mesure, toujours challengeants, des programmes de formations et certifications
                            permanents, ainsi qu'une grande place laissée au développement de projets personnels.
                        </p>
                    </div>
                    <div className="divSizing3">
                        <Image className="imageSizingSplit3" src={Team} alt="Équipe" />
                        <h2>Structure horizontale</h2>
                        <p className="textSizingSplit3 text-justify">
                            Être Agile, c’est d’abord un état d’esprit, et c’est avant tout concevoir qu'au sein d'une
                            organisation, chacun est important.
                            <br />
                            La hiérarchie n'est pas la meilleure façon de favoriser l'innovation et la productivité :
                            chez Code Insider, chacun est impliqué dans le processus de prise de décision. Nous tenons à
                            ce que votre travail au sein de Code Insider vienne de vous.
                        </p>
                    </div>
                </div>
                <div className="margin-bottom">
                    <Button link="/insider" variant="light">
                        Qu'est ce qu'un insider ?
                    </Button>
                </div>
            </TriangleContainer>
        </>
    );
}
