import DeezerIcon from './Components/Icons/SocialMedias/DeezerIcon';
import InstagramIcon from './Components/Icons/SocialMedias/InstagramIcon';
import LinkedinIcon from './Components/Icons/SocialMedias/LinkedinIcon';
import SoundcloudIcon from './Components/Icons/SocialMedias/SoundcloudIcon';
import SpotifyIcon from './Components/Icons/SocialMedias/SpotifyIcon';
import TwitterIcon from './Components/Icons/SocialMedias/TwitterIcon';
import YoutubeIcon from './Components/Icons/SocialMedias/YoutubeIcon';

export type MediaLink = {
    name: string;
    icon: JSX.Element;
    href: string;
};

export const deezer: MediaLink = {
    name: 'Deezer',
    icon: <DeezerIcon />,
    href: 'https://www.deezer.com/en/show/2353612',
};
export const spotify: MediaLink = {
    name: 'Spotify',
    icon: <SpotifyIcon />,
    href: 'https://open.spotify.com/show/0tSeh10ir9tLhYsI6jVt0l',
};
export const soundcloud: MediaLink = {
    name: 'Soundcloud',
    icon: <SoundcloudIcon />,
    href: 'https://soundcloud.com/user-447648199/sets/code-insider-podcasts-1',
};
export const youtube: MediaLink = {
    name: 'Youtube',
    icon: <YoutubeIcon />,
    href: 'https://www.youtube.com/channel/UCXwE6o9pRe7azShpqnruWDg',
};

export const instagram: MediaLink = {
    name: 'Instagram',
    icon: <InstagramIcon />,
    href: 'https://www.instagram.com/code_insider/',
};
export const linkedin: MediaLink = {
    name: 'Linkedin',
    icon: <LinkedinIcon />,
    href: 'https://www.linkedin.com/company/code-insider?originalSubdomain=fr',
};
export const twitter: MediaLink = {
    name: 'Twitter',
    icon: <TwitterIcon />,
    href: 'https://twitter.com/Code_Insider',
};

export const podcasts: MediaLink[] = [deezer, spotify, soundcloud];

export const videos: MediaLink[] = [youtube];

export const social: MediaLink[] = [instagram, linkedin, twitter];
