/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebsiteResourcesApplicationForm,
} from '../models';
import {
    WebsiteResourcesApplicationFormFromJSON,
    WebsiteResourcesApplicationFormToJSON,
} from '../models';

export interface SendApplicationConfirmationEmailRequest {
    websiteResourcesApplicationForm?: WebsiteResourcesApplicationForm;
}

/**
 * 
 */
export class ApplicationFormApi extends runtime.BaseAPI {

    /**
     * Send a confirmation mail CC an Insider
     */
    async sendApplicationConfirmationEmailRaw(requestParameters: SendApplicationConfirmationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/ApplicationForm/confirmation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebsiteResourcesApplicationFormToJSON(requestParameters.websiteResourcesApplicationForm),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send a confirmation mail CC an Insider
     */
    async sendApplicationConfirmationEmail(requestParameters: SendApplicationConfirmationEmailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendApplicationConfirmationEmailRaw(requestParameters, initOverrides);
    }

}
