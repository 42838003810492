import React, { Component } from 'react';

import './index.css';

export default class TriangleContainer extends Component {
    render() {
        return (
            <div
                className={
                    'triangleContainer' +
                    (this.props.behind ? ' triangleContainerBehind' : '') +
                    (this.props.variant
                        ? ' triangleContainer' + this.props.variant[0].toUpperCase() + this.props.variant.substring(1)
                        : '')
                }
            >
                <div className="triangleContainerDeconstructCSSBug">{this.props.children}</div>
            </div>
        );
    }
}
