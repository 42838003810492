/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GoogleApisYouTubeV3DataThumbnail } from './GoogleApisYouTubeV3DataThumbnail';
import {
    GoogleApisYouTubeV3DataThumbnailFromJSON,
    GoogleApisYouTubeV3DataThumbnailFromJSONTyped,
    GoogleApisYouTubeV3DataThumbnailToJSON,
} from './GoogleApisYouTubeV3DataThumbnail';

/**
 * 
 * @export
 * @interface GoogleApisYouTubeV3DataThumbnailDetails
 */
export interface GoogleApisYouTubeV3DataThumbnailDetails {
    /**
     * 
     * @type {GoogleApisYouTubeV3DataThumbnail}
     * @memberof GoogleApisYouTubeV3DataThumbnailDetails
     */
    _default?: GoogleApisYouTubeV3DataThumbnail;
    /**
     * 
     * @type {GoogleApisYouTubeV3DataThumbnail}
     * @memberof GoogleApisYouTubeV3DataThumbnailDetails
     */
    high?: GoogleApisYouTubeV3DataThumbnail;
    /**
     * 
     * @type {GoogleApisYouTubeV3DataThumbnail}
     * @memberof GoogleApisYouTubeV3DataThumbnailDetails
     */
    maxres?: GoogleApisYouTubeV3DataThumbnail;
    /**
     * 
     * @type {GoogleApisYouTubeV3DataThumbnail}
     * @memberof GoogleApisYouTubeV3DataThumbnailDetails
     */
    medium?: GoogleApisYouTubeV3DataThumbnail;
    /**
     * 
     * @type {GoogleApisYouTubeV3DataThumbnail}
     * @memberof GoogleApisYouTubeV3DataThumbnailDetails
     */
    standard?: GoogleApisYouTubeV3DataThumbnail;
    /**
     * 
     * @type {string}
     * @memberof GoogleApisYouTubeV3DataThumbnailDetails
     */
    eTag?: string | null;
}

/**
 * Check if a given object implements the GoogleApisYouTubeV3DataThumbnailDetails interface.
 */
export function instanceOfGoogleApisYouTubeV3DataThumbnailDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GoogleApisYouTubeV3DataThumbnailDetailsFromJSON(json: any): GoogleApisYouTubeV3DataThumbnailDetails {
    return GoogleApisYouTubeV3DataThumbnailDetailsFromJSONTyped(json, false);
}

export function GoogleApisYouTubeV3DataThumbnailDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleApisYouTubeV3DataThumbnailDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_default': !exists(json, 'default__') ? undefined : GoogleApisYouTubeV3DataThumbnailFromJSON(json['default__']),
        'high': !exists(json, 'high') ? undefined : GoogleApisYouTubeV3DataThumbnailFromJSON(json['high']),
        'maxres': !exists(json, 'maxres') ? undefined : GoogleApisYouTubeV3DataThumbnailFromJSON(json['maxres']),
        'medium': !exists(json, 'medium') ? undefined : GoogleApisYouTubeV3DataThumbnailFromJSON(json['medium']),
        'standard': !exists(json, 'standard') ? undefined : GoogleApisYouTubeV3DataThumbnailFromJSON(json['standard']),
        'eTag': !exists(json, 'eTag') ? undefined : json['eTag'],
    };
}

export function GoogleApisYouTubeV3DataThumbnailDetailsToJSON(value?: GoogleApisYouTubeV3DataThumbnailDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default__': GoogleApisYouTubeV3DataThumbnailToJSON(value._default),
        'high': GoogleApisYouTubeV3DataThumbnailToJSON(value.high),
        'maxres': GoogleApisYouTubeV3DataThumbnailToJSON(value.maxres),
        'medium': GoogleApisYouTubeV3DataThumbnailToJSON(value.medium),
        'standard': GoogleApisYouTubeV3DataThumbnailToJSON(value.standard),
        'eTag': value.eTag,
    };
}

