import { useQuery } from '@tanstack/react-query';

import { SpotifyApi } from '../../../api';
import useApi from '../../../hooks/useApi';
import { EmbedSpotifyEpisode } from '../../Embeds/spotify';
import { SocialMediaCardContainer } from '../SocialMediaCardContainer';

export function PodcastMesonryTile({ withLink = false, count = 3 }: { withLink?: boolean; count?: number }) {
    const spotifyApi = useApi(SpotifyApi);

    const query = useQuery({
        queryKey: ['spotify', 'episodes'],
        queryFn: () => spotifyApi.getSpotifyEpisodes({ limit: 10, offset: 0 }),
    });

    return (
        <SocialMediaCardContainer name="Podcasts" url={withLink ? '/podcasts' : undefined}>
            {query.data?.items?.slice(0, count).map((episode) => (
                <div key={episode.id}>{episode.id && <EmbedSpotifyEpisode episodeId={episode.id} width="100%" />}</div>
            ))}
        </SocialMediaCardContainer>
    );
}
