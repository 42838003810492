import axios from 'axios';
import { atom, useAtom } from 'jotai';
import { ChangeEventHandler } from 'react';
import { Image } from 'react-bootstrap';

import '../Button/index.css';
// import { TwitterTimeline } from '../Embeds/twitter';
import '../TextField/index.css';
import BG from './Images/bg.png';
// import { NosPlateformes } from './NosPlateformes';
// import { NosPodcastsAudio } from './NosPodcastsAudio';
// import { showSocialMediasAtom } from './atoms';
import './index.css';

const emailAtom = atom('');
const outputAtom = atom(<></>);

export default function NewsletterSection() {
    // const [showSocialMedias] = useAtom(showSocialMediasAtom);
    const [email, setEmail] = useAtom(emailAtom);
    const [output, setOutput] = useAtom(outputAtom);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setEmail((event.target as HTMLInputElement).value);
    };

    const handleSubmit = () => {
        //Save the mailing adresse in the DB
        axios
            .post(
                'api/EmailNewsletters',
                {
                    email: email,
                },
                {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                },
            )
            .then(() => {
                //Send the confirmation mails
                axios
                    .post(
                        'api/EmailNewsletters/confirmation',
                        {
                            email: email,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                            },
                        },
                    )
                    .then(() => {
                        setOutput(<p style={{ color: 'green' }}>Vous êtes inscrit, merci !</p>);
                    })
                    .catch(() => {
                        setOutput(<p style={{ color: 'red' }}>Une erreur est survenue, merci de réessayer.</p>);
                    });
            })
            .catch(() => {
                setOutput(<p style={{ color: 'red' }}>Une erreur est survenue, merci de réessayer.</p>);
            });
    };

    return (
        <div id="newsletterBlock">
            <h1 className="pb-2">Actualités des Insiders</h1>
            <div id="newsletterContainer">
                <div>
                    <div id="newsletterTitle1">Ne ratez plus aucun article, abonnez-vous à la newsletter !</div>
                    <div className="mb-[100px] text-gray-500">
                        1 article pertinent toutes les deux semaines sur l'actualité Tech
                    </div>
                    {output}
                    <input
                        className="textField"
                        type="email"
                        placeholder="Votre adresse e-mail"
                        onChange={handleChange}
                    />
                    <div className="buttonContainer" style={{ display: 'inline-block' }}>
                        <div className="button" onClick={handleSubmit}>
                            J'envoie
                        </div>
                    </div>
                </div>
                <Image src={BG} alt="Newsletter Background" />
            </div>
        </div>
    );
}
