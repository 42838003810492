import { animated, useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga';
import { Link, useLocation } from 'react-router-dom';

const GA_TRACKING_ID = 'G-5J5W3H5Y0E';

function Cookieman({ size, timing }: { size: string; timing: number }) {
    const [isBooped, setIsBooped] = useState(false);

    useEffect(() => {
        if (!isBooped) {
            return;
        }

        const timeoutId = window.setTimeout(() => {
            setIsBooped(false);
        }, timing);

        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [isBooped, timing]);

    const springs = useSpring({
        transform: isBooped ? `rotate(${20}deg)` : `rotate(0deg)`,
        config: {
            tension: 300,
            friction: 8,
        },
    });

    const trigger = () => {
        setIsBooped(true);
    };

    return (
        <animated.img
            style={{ ...springs }}
            src="/img/goodbye_cookieman.png"
            onMouseEnter={trigger}
            height={size}
            width={size}
        />
    );
}

export default function CodeInsiderCookieConsent() {
    const location = useLocation();
    useEffect(() => {
        if (getCookieConsentValue()) {
            ReactGA.initialize(GA_TRACKING_ID);
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [location]);

    function handleDecline() {
        //remove google analytics cookies
        Cookies.remove('_ga');
        Cookies.remove('_gat');
        Cookies.remove('_gid');
    }

    // Accepts only when initialized
    function handleAccept() {}

    return (
        <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="OK pour moi"
            declineButtonText="Non merci"
            buttonClasses="btn btn-primary"
            declineButtonClasses="btn btn-secondary"
            style={{ background: 'black' }}
            buttonStyle={{ color: 'white', fontSize: '1rqem', backgroundColor: 'green' }}
            expires={150} // 150 days
            onDecline={handleDecline}
            onAccept={handleAccept}
        >
            <Cookieman size="70px" timing={200} />
            Bonjour 👋 Nous c’est les Cookistronaute ! On aimerait t’accompagner pendant la visite de notre univers…
            C’est OK pour toi ? &nbsp;<Link to="/mentions-legales">Politique de confidentialité</Link>
        </CookieConsent>
    );
}
