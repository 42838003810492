/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SpotifyAPIWebSimpleEpisode } from './SpotifyAPIWebSimpleEpisode';
import {
    SpotifyAPIWebSimpleEpisodeFromJSON,
    SpotifyAPIWebSimpleEpisodeFromJSONTyped,
    SpotifyAPIWebSimpleEpisodeToJSON,
} from './SpotifyAPIWebSimpleEpisode';

/**
 * 
 * @export
 * @interface SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode
 */
export interface SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode {
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode
     */
    href?: string | null;
    /**
     * 
     * @type {Array<SpotifyAPIWebSimpleEpisode>}
     * @memberof SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode
     */
    items?: Array<SpotifyAPIWebSimpleEpisode> | null;
    /**
     * 
     * @type {number}
     * @memberof SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode
     */
    limit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode
     */
    next?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode
     */
    offset?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode
     */
    previous?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode
     */
    total?: number | null;
}

/**
 * Check if a given object implements the SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode interface.
 */
export function instanceOfSpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisodeFromJSON(json: any): SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode {
    return SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisodeFromJSONTyped(json, false);
}

export function SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'href': !exists(json, 'href') ? undefined : json['href'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(SpotifyAPIWebSimpleEpisodeFromJSON)),
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisodeToJSON(value?: SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'href': value.href,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(SpotifyAPIWebSimpleEpisodeToJSON)),
        'limit': value.limit,
        'next': value.next,
        'offset': value.offset,
        'previous': value.previous,
        'total': value.total,
    };
}

