import { useQuery } from '@tanstack/react-query';

import { YoutubeApi } from '../../../api';
import useApi from '../../../hooks/useApi';
import { YoutubeEmbed } from '../../Embeds/youtube';
import { SocialMediaCardContainer } from '../SocialMediaCardContainer';

export function VideosMesonryTile({ withLink = false, count = 3 }: { withLink?: boolean; count?: number }) {
    const youtubeApi = useApi(YoutubeApi);

    const query = useQuery({
        queryKey: ['youtube', 'videos'],
        queryFn: () => youtubeApi.getChannelVideos(),
    });

    return (
        <SocialMediaCardContainer name="Vidéos" url={withLink ? '/videos' : undefined}>
            {query.data?.slice(0, count).map((video) => (
                <YoutubeEmbed
                    key={video.resourceId?.videoId}
                    title={video.title ?? ''}
                    videoId={video.resourceId?.videoId ?? ''}
                    className="rounded-md"
                    width={'100%'}
                ></YoutubeEmbed>
            ))}
        </SocialMediaCardContainer>
    );
}
