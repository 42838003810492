import { useQuery } from '@tanstack/react-query';

import getPosts from '../../../Apis/wordpress';
import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import { WordpressHightlight } from '../../Embeds/wordpress';
import { BlogMesonryTile } from '../../Social/MesonryTiles/BlogMesonryTile';
import { PodcastMesonryTile } from '../../Social/MesonryTiles/PodcastMesonryTile';
import { TwitterMesonryTile } from '../../Social/MesonryTiles/TwitterMesonryTile';
import { VideosMesonryTile } from '../../Social/MesonryTiles/VideosMesonryTile';
import { SocialMediaMosaicContainer } from '../../Social/SocialMediaMosaicContainer';
import PCImage from './Images/PC.png';

export default function NewsPage() {
    useFormattedDocumentTitle('Actualités');

    const wordpressQuery = useQuery({
        queryKey: ['wordpress', 'posts', `page=1`, `per_page=4`],
        queryFn: () => getPosts({ perPage: 4 }),
    });

    return (
        <div className="bg-[#014F9B]">
            <div className="d-flex justify-content-center items-center">
                <img src={PCImage} alt="..." className="rounded-circle" width="150px" height="150px" />

                <h1 className="m-0 text-6xl text-white">News</h1>
            </div>
            <div className="mb-5">
                <div className="2xl:3/4 mx-auto w-full lg:w-11/12 xl:w-5/6">
                    <div>
                        <div className="m-3 rounded-lg border border-gray-300 bg-gray-100 p-2 shadow-lg md:px-4 lg:px-6">
                            <h3 className="font-bold">Dernier post</h3>
                            {wordpressQuery.data?.items?.slice(0, 1).map((article) => (
                                <WordpressHightlight key={article.id} article={article} />
                            ))}
                        </div>
                        <SocialMediaMosaicContainer>
                            <PodcastMesonryTile withLink />
                            <BlogMesonryTile withLink skipFirstPost={true} />
                            <VideosMesonryTile withLink />
                            <TwitterMesonryTile />
                        </SocialMediaMosaicContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}
