import { animated, useSpring } from '@react-spring/web';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const AnimatedLink = animated(Link);

export function GoBackLink({ children, to }: { to: string; children: React.ReactNode }) {
    const [isHovered, setIsHovered] = useState(false);

    const springs = useSpring({
        transform: isHovered ? `scale(1.05)` : `scale(1)`,
        config: {
            tension: 300,
            friction: 15,
        },
    });

    return (
        <AnimatedLink
            to={to}
            style={{ ...springs }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="m-5 flex shrink items-center gap-3 rounded-xl bg-slate-200 p-5"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
            </svg>
            <span>{children}</span>
        </AnimatedLink>
    );
}

export function ReturnToArticlesLink() {
    const [isHovered, setIsHovered] = useState(false);

    const springs = useSpring({
        transform: isHovered ? `scale(1.05)` : `scale(1)`,
        config: {
            tension: 300,
            friction: 15,
        },
    });

    return (
        <animated.a
            style={{ ...springs }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            href="/blog"
            className="m-5 flex shrink items-center gap-3 rounded-xl bg-slate-200 p-5"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
            </svg>
            <span>Retour aux articles</span>
        </animated.a>
    );
}
