import { REACT_APP_API_URL, REACT_APP_ENVIRONMENT } from '../../conf';
import FacebookIcon from '../Icons/SocialMedias/FacebookIcon';
import InstagramIcon from '../Icons/SocialMedias/InstagramIcon';
import LinkedinIcon from '../Icons/SocialMedias/LinkedinIcon';
import SpotifyIcon from '../Icons/SocialMedias/SpotifyIcon';
import TwitterIcon from '../Icons/SocialMedias/TwitterIcon';
import YoutubeIcon from '../Icons/SocialMedias/YoutubeIcon';
import Newsletter from '../Newsletter';
import SiteMap, { SiteLink } from '../SiteMap';
import './index.css';

const socialMedias: SiteLink[] = [
    {
        name: 'Youtube',
        content: <YoutubeIcon fill="white" className="h-6 w-6" />,
        href: 'https://www.youtube.com/channel/UCXwE6o9pRe7azShpqnruWDg/?sub_confirmation=1',
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        name: 'Twitter',
        content: <TwitterIcon fill="white" className="h-6 w-6" />,
        href: 'https://twitter.com/Code_Insider',
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        name: 'Facebook',
        content: <FacebookIcon fill="white" className="h-6 w-6" />,

        href: 'https://www.facebook.com/codeinsider/',
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        name: 'Linkedin',
        content: <LinkedinIcon fill="white" className="h-6 w-6" />,
        href: 'https://www.linkedin.com/company/code-insider/',
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        name: 'Instagram',
        content: <InstagramIcon fill="white" className="h-6 w-6" />,
        href: 'https://www.instagram.com/codeinsider/',
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        name: 'Spotify',
        content: <SpotifyIcon fill="white" className="h-6 w-6" />,
        href: 'https://open.spotify.com/show/0tSeh10ir9tLhYsI6jVt0l',
        target: '_blank',
        rel: 'noopener noreferrer',
    },
];

export default function Footer() {
    return (
        <>
            <div>
                <Newsletter />
            </div>
            <div>
                <SiteMap />
            </div>
            <div className="flex items-center justify-between bg-[#014f9b] px-10 py-5">
                <div className="p-0 text-white">
                    {new Date().getFullYear()} © Tous droits réservés.
                    <br />
                    <a href="/mentions-legales" className="text-blue-400">
                        Mentions Légales
                    </a>
                </div>
                <div className="flex gap-1">
                    {socialMedias.map((socialMedia) => (
                        <a
                            className="text-white"
                            href={socialMedia.href}
                            key={socialMedia.name}
                            target={socialMedia.target}
                            rel={socialMedia.rel}
                        >
                            {socialMedia.content ?? socialMedia.name}
                        </a>
                    ))}
                </div>
            </div>
            {REACT_APP_ENVIRONMENT !== 'production' && (
                <div className="sticky bottom-0 left-0 right-0 bg-blue-500/20 text-center text-white hover:bg-blue-500 hover:text-white">
                    <span className="">
                        {REACT_APP_ENVIRONMENT} build || api: {REACT_APP_API_URL}
                    </span>
                </div>
            )}
        </>
    );
}
