import React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';

import BackgroundImage from './Images/Fond_Type_Forme.png';
import Planet_DarkPink_Img from './Images/Planet_DarkPink.png';
import Planet_Brown_Img from './Images/Planète marron.png';
import Planet_Orange_Img from './Images/Planète orange.png';
import Planet_Green_Img from './Images/Planète verte.png';
import './index.css';

const Planet = ({ id, title, text, size, marginTop, className, image }) => {
    return (
        <div id={id} className={className}>
            <div
                style={{ marginTop: marginTop, width: '400px' }}
                className="planet-container d-flex flex-column align-items-center justify-content-center"
                tabIndex="0"
            >
                <h4 className="pb-0 text-center" style={{ color: 'white', fontSize: '2em' }}>
                    {title}
                </h4>
                <div className="parent">
                    <img className="planet-image" src={image ?? 'https://via.placeholder.com/100'} />

                    <div
                        className="planet-infobubble rounded-circle d-flex flex-column align-items-center justify-content-center"
                        style={{ height: size, width: size }}
                    >
                        <p className=" p-5 text-center" style={{ fontSize: '1.1em' }}>
                            {text}
                        </p>
                    </div>
                </div>

                <div></div>
            </div>
        </div>
    );
};

export default function ValuesSection() {
    return (
        <>
            <ParallaxBanner
                layers={[
                    {
                        image: BackgroundImage,
                        speed: -20,
                        style: { backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' },
                    },
                    {
                        speed: 0.5,
                        amount: 0.5,
                        children: (
                            <h2
                                style={{ fontSize: '4em', color: 'white', textTransform: 'uppercase' }}
                                className="mb-0"
                            >
                                Nos Valeurs
                            </h2>
                        ),
                    },
                ]}
                className="pb-5"
            >
                <h2
                    style={{ fontSize: '4em', color: 'white', textTransform: 'uppercase', zIndex: -1 }}
                    className="mb-0 pb-0"
                >
                    Nos Valeurs
                </h2>
                <div className="row mr-xl-5 ml-xl-5 mr-md-0 ml-md-0">
                    <div className="col-sm-12 col-md-6 col-xl-3 mb-md-3 d-flex justify-content-center align-items-center w-100 mb-3 flex-row">
                        <Planet
                            id="planet1"
                            size="350px"
                            scale="1"
                            marginTop="10px"
                            title="Honnêteté"
                            text="Chez Code Insider, nous sommes convaincus que l’intégrité et la communication sont les prérequis vitaux d’une entreprise pérenne."
                            image={Planet_Brown_Img}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-xl-3 mb-md-3 d-flex justify-content-center align-items-center w-100 mb-3 flex-row">
                        <Planet
                            id="planet2"
                            size="350px"
                            scale="1"
                            marginTop="20px"
                            title="Qualité"
                            text="Si la qualité est un pilier du Software Craftsmanship, elle se retrouve aujourd’hui solidement ancrée dans nos valeurs ; c’est un état d’esprit commun à l’ensemble de nos Insider"
                            image={Planet_DarkPink_Img}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-xl-3 mb-md-3 d-flex justify-content-center align-items-center w-100 mb-3 flex-row">
                        <Planet
                            id="planet3"
                            size="350px"
                            scale="1"
                            marginTop="10px"
                            title="Bienveillance"
                            text="Nous sommes résolument convaincus qu’entreprise et bienveillance ne sont pas antinomiques, bien au contraire. Notre recette ? Un fort esprit d’équipe, une vraie capacité d’écoute active, et de la positivité !"
                            image={Planet_Orange_Img}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-xl-3 mb-md-3 d-flex justify-content-center align-items-center w-100 mb-3 flex-row">
                        <Planet
                            id="planet4"
                            size="350px"
                            scale="1"
                            marginTop="0px"
                            title="Loyauté"
                            text="Parce que nous mettons tout en œuvre pour cultiver le sentiment d’appartenance à notre communauté de Crafts(wo)men, nous donnons et attendons un engagement fort et respectueux l’un envers l’autre."
                            image={Planet_Green_Img}
                        />
                    </div>
                </div>
            </ParallaxBanner>
        </>
    );
}
