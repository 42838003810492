import { TwitterTimelineEmbed } from 'react-twitter-embed';

export function TwitterTimeline({ height }: { height?: number }) {
    return (
        <TwitterTimelineEmbed
            sourceType="profile"
            screenName="Code_Insider"
            options={{
                height: height ?? 400,
            }}
        />
    );
}
