import axios from 'axios';
import React, { Component } from 'react';

import Event from '../Event';

export default class Events extends Component {
    state = {
        eventsList: [],
        nextEventsList: [],
    };

    constructor(props) {
        super(props);

        this.setEventsList();
    }

    setEventsList() {
        axios.get('api/Evenements', {}).then((res) => {
            this.setState({ eventsList: res.data });
            this.sortEventsList();
        });
    }

    sortEventsList() {
        var currentDate = new Date();
        this.setState((state) => ({
            nextEventsList: state.eventsList.filter((e) => new Date(e.dateStartEvent) > currentDate),
        }));
    }

    render() {
        return (
            <>
                <h1>Prochains évènements</h1>
                <Event.List>
                    {this.state.nextEventsList.map((item) => (
                        <Event
                            key={item.id}
                            name={item.titreEvent}
                            date={item.dateStartEvent}
                            location={item.lieuEvent}
                            id={item.id}
                        />
                    ))}
                </Event.List>
            </>
        );
    }
}
