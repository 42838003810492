/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SpotifyAPIWebImage } from './SpotifyAPIWebImage';
import {
    SpotifyAPIWebImageFromJSON,
    SpotifyAPIWebImageFromJSONTyped,
    SpotifyAPIWebImageToJSON,
} from './SpotifyAPIWebImage';
import type { SpotifyAPIWebItemType } from './SpotifyAPIWebItemType';
import {
    SpotifyAPIWebItemTypeFromJSON,
    SpotifyAPIWebItemTypeFromJSONTyped,
    SpotifyAPIWebItemTypeToJSON,
} from './SpotifyAPIWebItemType';
import type { SpotifyAPIWebResumePoint } from './SpotifyAPIWebResumePoint';
import {
    SpotifyAPIWebResumePointFromJSON,
    SpotifyAPIWebResumePointFromJSONTyped,
    SpotifyAPIWebResumePointToJSON,
} from './SpotifyAPIWebResumePoint';

/**
 * 
 * @export
 * @interface SpotifyAPIWebSimpleEpisode
 */
export interface SpotifyAPIWebSimpleEpisode {
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    audioPreviewUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    durationMs?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    explicit?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    externalUrls?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    href?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    id?: string | null;
    /**
     * 
     * @type {Array<SpotifyAPIWebImage>}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    images?: Array<SpotifyAPIWebImage> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    isExternallyHosted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    isPlayable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebSimpleEpisode
     * @deprecated
     */
    language?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    languages?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    releaseDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    releaseDatePrecision?: string | null;
    /**
     * 
     * @type {SpotifyAPIWebResumePoint}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    resumePoint?: SpotifyAPIWebResumePoint;
    /**
     * 
     * @type {SpotifyAPIWebItemType}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    type?: SpotifyAPIWebItemType;
    /**
     * 
     * @type {string}
     * @memberof SpotifyAPIWebSimpleEpisode
     */
    uri?: string | null;
}

/**
 * Check if a given object implements the SpotifyAPIWebSimpleEpisode interface.
 */
export function instanceOfSpotifyAPIWebSimpleEpisode(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SpotifyAPIWebSimpleEpisodeFromJSON(json: any): SpotifyAPIWebSimpleEpisode {
    return SpotifyAPIWebSimpleEpisodeFromJSONTyped(json, false);
}

export function SpotifyAPIWebSimpleEpisodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpotifyAPIWebSimpleEpisode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'audioPreviewUrl': !exists(json, 'audioPreviewUrl') ? undefined : json['audioPreviewUrl'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'durationMs': !exists(json, 'durationMs') ? undefined : json['durationMs'],
        'explicit': !exists(json, 'explicit') ? undefined : json['explicit'],
        'externalUrls': !exists(json, 'externalUrls') ? undefined : json['externalUrls'],
        'href': !exists(json, 'href') ? undefined : json['href'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'images': !exists(json, 'images') ? undefined : (json['images'] === null ? null : (json['images'] as Array<any>).map(SpotifyAPIWebImageFromJSON)),
        'isExternallyHosted': !exists(json, 'isExternallyHosted') ? undefined : json['isExternallyHosted'],
        'isPlayable': !exists(json, 'isPlayable') ? undefined : json['isPlayable'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'languages': !exists(json, 'languages') ? undefined : json['languages'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'releaseDate': !exists(json, 'releaseDate') ? undefined : json['releaseDate'],
        'releaseDatePrecision': !exists(json, 'releaseDatePrecision') ? undefined : json['releaseDatePrecision'],
        'resumePoint': !exists(json, 'resumePoint') ? undefined : SpotifyAPIWebResumePointFromJSON(json['resumePoint']),
        'type': !exists(json, 'type') ? undefined : SpotifyAPIWebItemTypeFromJSON(json['type']),
        'uri': !exists(json, 'uri') ? undefined : json['uri'],
    };
}

export function SpotifyAPIWebSimpleEpisodeToJSON(value?: SpotifyAPIWebSimpleEpisode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'audioPreviewUrl': value.audioPreviewUrl,
        'description': value.description,
        'durationMs': value.durationMs,
        'explicit': value.explicit,
        'externalUrls': value.externalUrls,
        'href': value.href,
        'id': value.id,
        'images': value.images === undefined ? undefined : (value.images === null ? null : (value.images as Array<any>).map(SpotifyAPIWebImageToJSON)),
        'isExternallyHosted': value.isExternallyHosted,
        'isPlayable': value.isPlayable,
        'language': value.language,
        'languages': value.languages,
        'name': value.name,
        'releaseDate': value.releaseDate,
        'releaseDatePrecision': value.releaseDatePrecision,
        'resumePoint': SpotifyAPIWebResumePointToJSON(value.resumePoint),
        'type': SpotifyAPIWebItemTypeToJSON(value.type),
        'uri': value.uri,
    };
}

