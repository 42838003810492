import axios from 'axios';
import { Component } from 'react';
import { Image } from 'react-bootstrap';

import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import Button from '../../Button';
import Header from '../../Header';
import TriangleContainer from '../../TriangleContainer';
import HeaderImage from './Images/header.png';
import Step2 from './Images/step2.png';
import Step3 from './Images/step3.png';
import Step4 from './Images/step4.png';
import Step5 from './Images/step5.png';
import Step6 from './Images/step6.png';
import { JobOfferList } from './JobOffers';
import './index.css';

class PageRecruiting extends Component {
    state = {
        prenom: '',
        nom: '',
        email: '',
        phone: '',
        gitHub: '',
        blog: '',
        linkedIn: '',
        twitter: '',
        comment: '',
        output: '',
    };

    componentDidMount() {}

    handleChange = (event: any) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSubmit = () => {
        //Axios to PostApplicationForm has been removed

        //Send the application form by mail
        axios
            .post(
                'api/ApplicationForm/confirmation',
                {
                    Prenom: this.state.prenom,
                    Nom: this.state.nom,
                    Email: this.state.email,
                    Phone: this.state.phone,
                    GitHub: this.state.gitHub,
                    Blog: this.state.blog,
                    LinkedIn: this.state.linkedIn,
                    Twitter: this.state.twitter,
                    Comment: this.state.comment,
                },
                {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                },
            )
            .then(() => {
                this.setState({
                    output: (
                        <p style={{ color: 'green' }}>
                            Merci pour votre candidature, nous l'examinons, et revenons vers vous dans les plus brefs
                            délais !
                        </p>
                    ),
                });
            })
            .catch(() => {
                this.setState({ output: <p style={{ color: 'red' }}>Une erreur est survenue, merci de réessayer.</p> });
            });
    };

    render() {
        return (
            <>
                <Header title="On recrute !" image={HeaderImage} />
                <div className="mb-0 lg:mb-20">
                    <JobOfferList />
                </div>
                <TriangleContainer>
                    <div className="recruitingStepContainer">
                        <div className="recruitingRight">
                            <h2>Étape 1/5</h2>
                            <p className="textSizing text-justify">
                                Première étape du processus de recrutement visant à faire une pré qualification
                                téléphonique d’environ 15 minutes afin d’évaluer les attentes professionnelles du
                                candidat.
                            </p>
                            <Image className="recruitingImageSizing" src={Step2} alt="Étape 2" />
                        </div>
                        <div className="recruitingLeft">
                            <h2>Étape 2/5</h2>
                            <p className="textSizing text-justify">
                                Deuxième étape du processus de recrutement visant à découvrir notre interlocuteur au gré
                                d’une rencontre (son parcours, ses principaux projets professionnels, ses motivations et
                                ambitions, ...), et à lui présenter Code Insider.
                            </p>
                            <Image className="recruitingImageSizing" src={Step3} alt="Étape 3" />
                        </div>
                        <div className="recruitingRight">
                            <h2>Étape 3/5</h2>
                            <p className="textSizing text-justify">
                                Troisième étape du processus de recrutement permettant au candidat d’échanger et de
                                faire des exercices pratiques avec un Insider sur le format « Pair Programming », d’une
                                durée d’environ 2h.
                            </p>
                            <Image className="recruitingImageSizing" src={Step4} alt="Étape 4" />
                        </div>
                        <div className="recruitingLeft">
                            <h2>Étape 4/5</h2>
                            <p className="textSizing text-justify">
                                La dernière étape du processus de recrutement visant à présenter de manière plus précise
                                l’offre salariale ainsi que les différents avantages présents chez Code Insider.
                            </p>
                            <Image className="recruitingImageSizing" src={Step5} alt="Étape 5" />
                        </div>
                        <div className="recruitingRight">
                            <h2>Étape 5/5</h2>
                            <p className="text-centered textSizing">Bienvenu chez les Insiders !</p>
                            <Image className="recruitingImageSizingSmall" src={Step6} alt="Étape 6" />
                        </div>
                    </div>
                    <h2>En attendant d'être contacté, n'hésitez pas à regarder nos évènements !</h2>
                    <Button variant="light" link="/events">
                        Prochais évènements
                    </Button>
                </TriangleContainer>
            </>
        );
    }
}

// Wrapper to use the hook
export default function WrappedPageRecruiting(props: any) {
    useFormattedDocumentTitle('On recrute !');

    return <PageRecruiting {...props} />;
}
