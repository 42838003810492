import ExternalLinkIcon from '../Icons/UI/ExternalLink';

export function SocialMediaCardContainer({
    name,
    url,
    icon,
    ...props
}: {
    name: string;
    url?: string;
    icon?: JSX.Element;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    return (
        <div className="m-3 rounded-lg border border-gray-300 bg-gray-100 p-2 shadow-lg">
            <div className="mb-2">
                {url ? (
                    <a
                        href={url}
                        className="group flex items-baseline justify-between rounded-xl px-5 text-black hover:bg-neutral-200 hover:text-black hover:shadow-inner active:bg-neutral-200 active:text-black active:shadow-inner"
                    >
                        <ExternalLinkIcon className="h-6 w-6 text-transparent" />
                        <h3 className="pt-4 pb-2">{name}</h3>
                        <ExternalLinkIcon className="h-6 w-6 text-transparent group-hover:text-black group-active:text-black" />
                    </a>
                ) : (
                    <h3 className="pt-4 pb-2">{name}</h3>
                )}
            </div>

            <div {...props} />
        </div>
    );
}
