/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A form post for candidacy
 * @export
 * @interface WebsiteResourcesApplicationForm
 */
export interface WebsiteResourcesApplicationForm {
    /**
     * 
     * @type {string}
     * @memberof WebsiteResourcesApplicationForm
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebsiteResourcesApplicationForm
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebsiteResourcesApplicationForm
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebsiteResourcesApplicationForm
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebsiteResourcesApplicationForm
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebsiteResourcesApplicationForm
     */
    offerName?: string | null;
}

/**
 * Check if a given object implements the WebsiteResourcesApplicationForm interface.
 */
export function instanceOfWebsiteResourcesApplicationForm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebsiteResourcesApplicationFormFromJSON(json: any): WebsiteResourcesApplicationForm {
    return WebsiteResourcesApplicationFormFromJSONTyped(json, false);
}

export function WebsiteResourcesApplicationFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebsiteResourcesApplicationForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'offerName': !exists(json, 'offerName') ? undefined : json['offerName'],
    };
}

export function WebsiteResourcesApplicationFormToJSON(value?: WebsiteResourcesApplicationForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prenom': value.prenom,
        'nom': value.nom,
        'email': value.email,
        'phone': value.phone,
        'comment': value.comment,
        'offerName': value.offerName,
    };
}

