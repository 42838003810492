import { animated, useSpring } from '@react-spring/web';
import { PrimitiveAtom, atom, useAtom } from 'jotai';
import { splitAtom } from 'jotai/utils';
import { useMemo, useRef, useState } from 'react';

import CahierImage from '../../../assets/images/Cahier.png';
import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import { selectedTagsAtom } from './atoms';
import { onlineResources } from './onlineResources';

function MyCard({ text }: { text: string }) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const springs = useSpring({
        transform: isHovered ? `scale(1.01)` : `scale(1)`,
        config: {
            tension: 300,
            friction: 8,
        },
    });

    return (
        <animated.div style={{ ...springs }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="h-[500px] w-[400px] rounded-lg bg-white shadow-xl">
                <img
                    className="h-2/3 w-full rounded-t object-cover"
                    // transition-shadow hover:shadow-none
                    src="https://images.unsplash.com/photo-1605557202138-097824c36ed2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=752&q=80"
                    alt=""
                />
                {text}
            </div>
        </animated.div>
    );
}

function LeftIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-16 w-16 text-white"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
        </svg>
    );
}

function RightIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-16 w-16 text-white"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
        </svg>
    );
}

const cardWidth = 400;

function CategoryCarousel() {
    const scrollZone = useRef<HTMLDivElement>(null);

    const sideScrollLeft = () => {
        scrollZone?.current?.scrollBy({
            left: scrollZone?.current ? -(scrollZone?.current?.offsetWidth - cardWidth) : -cardWidth,
            behavior: 'smooth',
        });
    };
    const sideScrollRight = () => {
        scrollZone?.current?.scrollBy({
            left: scrollZone?.current ? scrollZone?.current?.offsetWidth - cardWidth : cardWidth,
            behavior: 'smooth',
        });
    };

    return (
        <div className="relative overflow-auto">
            <div
                ref={scrollZone}
                className="relative flex w-full snap-x snap-mandatory gap-6 overflow-x-auto scroll-smooth py-14"
            >
                <div className="shrink-0">
                    <div className="w-20 shrink-0 sm:w-48"></div>
                </div>
                {[...Array(10)].map((_, index) => (
                    <div key={index} className="shrink-0 snap-center first:pl-8 last:pr-8">
                        <MyCard text={index.toString()} />
                    </div>
                ))}
                <div className="shrink-0">
                    <div className="w-20 shrink-0 sm:w-48"></div>
                </div>
            </div>

            <div className="invisible absolute left-0 top-1/2 mx-4 -translate-y-1/2 transform md:visible">
                <button onClick={sideScrollLeft} className="rounded-full border-0 bg-black/20 p-8 lg:p-16">
                    <LeftIcon />
                </button>
            </div>
            <div className="invisible absolute right-0 top-1/2 mx-4 -translate-y-1/2 transform md:visible">
                <button onClick={sideScrollRight} className="rounded-full border-0 bg-black/20 p-8 lg:p-16">
                    <RightIcon />
                </button>
            </div>
        </div>
    );
}

type TagProps = {
    name: string;
};

function Tag({ name }: TagProps) {
    return <div className="rounded-md bg-gray-200 px-2 py-1 text-sm font-medium text-gray-700">{name}</div>;
}

function ClickableTag({ name }: TagProps) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const springs = useSpring({
        transform: isHovered ? `scale(1.1)` : `scale(1)`,
        config: {
            tension: 600,
            friction: 16,
        },
    });

    return (
        <animated.button
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="rounded-lg border-none bg-blue-200 px-2 py-1 text-sm font-medium text-gray-700"
            style={{ ...springs }}
        >
            {name}
        </animated.button>
    );
}

const popularTags = ['React', 'Typescript', 'Node', 'CSS', 'HTML'];

function Tags() {
    return (
        <div className="flex flex-wrap gap-2">
            {popularTags.map((name) => (
                <ClickableTag name={name} />
            ))}
        </div>
    );
}

type TabItemProps = {
    id: number;
    name: string;
    color: string;
    color1: string;
    color2: string;
};

const initialTabs: TabItemProps[] = [
    { id: 0, name: 'C#', color: 'bg-[#963BAE]', color1: 'from-[#963BAE]', color2: 'to-[#963BAE]' },
    { id: 1, name: 'Java', color: 'bg-[#f89820]', color1: 'from-[#f89820]', color2: 'to-[#f89820]' },
    { id: 2, name: 'React', color: 'bg-[#61DBFB]', color1: 'from-[#61DBFB]', color2: 'to-[#61DBFB]' },
    { id: 3, name: 'Angular', color: 'bg-yellow-200', color1: 'from-yellow-200', color2: 'to-yellow-300' },
];

const tabsAtom = atom(initialTabs);
const tabsAtomAtom = splitAtom(tabsAtom);
const selectedTabAtom = atom<TabItemProps>(initialTabs[0]);

function TabItem({ tabAtom }: { tabAtom: PrimitiveAtom<TabItemProps> }) {
    const [tab] = useAtom(tabAtom);
    const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom);

    const handleClick = () => {
        setSelectedTab(tab);
    };

    return (
        <button
            onClick={handleClick}
            className={`rounded-3xl border-0 ${
                tab.id === selectedTab?.id
                    ? `bg-gradient-to-t ${selectedTab.color1} ${selectedTab.color2}`
                    : 'bg-transparent'
            } px-5 pb-2 pt-3 text-2xl  font-medium text-gray-700`}
        >
            {tab.name}
        </button>
    );
}

function Tabs() {
    const [tabsAtoms] = useAtom(tabsAtomAtom);
    const [selectedTab] = useAtom(selectedTabAtom);

    return (
        <div className="mx-10">
            <div className="ml-6">
                <div className="flex flex-col gap-3 md:flex-row">
                    {tabsAtoms.map((tabAtom) => (
                        <TabItem key={`${tabAtom}`} tabAtom={tabAtom} />
                    ))}
                </div>
            </div>
            <div className={`mt-3 h-96 w-full rounded-xl p-5 ${selectedTab?.color}`}>AAA</div>
        </div>
    );
}

export default function WikiPage() {
    useFormattedDocumentTitle('Wiki');

    const [tags] = useAtom(selectedTagsAtom);

    const filteredResources = useMemo(
        () => (tags.length ? onlineResources.filter((resource) => resource.tags?.includes(tags[0])) : onlineResources),
        [tags],
    );

    return (
        <div>
            <div className="md:mb-18 mt-36 mb-10 flex flex-col items-center justify-center gap-5 p-5 md:mt-40 md:flex-row md:gap-10 lg:gap-20">
                <img src={CahierImage} alt="cahier" className="h-48 w-48 md:h-60 md:w-60 lg:h-80 lg:w-80" />
                <div>
                    <h1 className="m-0 p-0 pb-2 text-center text-5xl md:text-left">La Code Insider Academy</h1>
                    <p className="text-center text-xl md:text-left">
                        Nos resources en ligne pour progresser efficacement
                    </p>
                    <div className="flex justify-center md:justify-start">
                        <Tags />
                    </div>
                </div>
            </div>

            <div className="mb-10">
                <CategoryCarousel />
            </div>

            <Tabs />
        </div>
    );
}
