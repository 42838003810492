/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GoogleApisYouTubeV3DataPlaylistItemSnippet,
} from '../models';
import {
    GoogleApisYouTubeV3DataPlaylistItemSnippetFromJSON,
    GoogleApisYouTubeV3DataPlaylistItemSnippetToJSON,
} from '../models';

/**
 * 
 */
export class YoutubeApi extends runtime.BaseAPI {

    /**
     * Get the list of youtube videos
     */
    async getChannelVideosRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GoogleApisYouTubeV3DataPlaylistItemSnippet>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Youtube/videos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GoogleApisYouTubeV3DataPlaylistItemSnippetFromJSON));
    }

    /**
     * Get the list of youtube videos
     */
    async getChannelVideos(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GoogleApisYouTubeV3DataPlaylistItemSnippet>> {
        const response = await this.getChannelVideosRaw(initOverrides);
        return await response.value();
    }

}
