import { useQuery } from '@tanstack/react-query';
import Lottie from 'lottie-react';
import { useMemo } from 'react';

import getPosts from '../../../Apis/wordpress';
import rocketAnimation from '../../../assets/lottie/lottie-rocket.json';
import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import useQueryParams from '../../../hooks/useQueryParams';
import { WordpressHightlight } from '../../Embeds/wordpress';
import Header from '../../Header';
import { PodcastMesonryTile } from '../../Social/MesonryTiles/PodcastMesonryTile';
import { TwitterMesonryTile } from '../../Social/MesonryTiles/TwitterMesonryTile';
import { VideosMesonryTile } from '../../Social/MesonryTiles/VideosMesonryTile';
import { SocialMediaSidebarContainer } from '../../Social/SocialMediaSidebarContainer';
import headerImg from './Images/header.png';

const perPage = 10;

export default function BlogPage() {
    useFormattedDocumentTitle('Blog');

    const queryParams = useQueryParams();
    const pageNumber = queryParams.get('page') ? parseInt(queryParams.get('page') as string) : 1;

    const articlesQuery = useQuery({
        queryKey: ['posts'],
        queryFn: () =>
            getPosts({
                page: pageNumber,
                perPage: perPage,
            }),
    });

    const totalPages = useMemo(() => articlesQuery.data?.totalPages ?? 0, [articlesQuery.data]);

    const Pagination = () => (
        <div className="flex items-center justify-center gap-5 p-10">
            {pageNumber > 1 && (
                <a className="rounded-full bg-blue-100 p-3" href={`/blog?page=${pageNumber - 1}`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-6 w-6"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>
                    <span className="ml-2">Page précédente</span>
                </a>
            )}
            <div>
                Page {pageNumber} / {totalPages ?? '...'}
            </div>
            {pageNumber < totalPages && (
                <a className="rounded-full bg-blue-100 p-3" href={`/blog?page=${pageNumber + 1}`}>
                    <span>Page suivante</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-6 w-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                    </svg>
                </a>
            )}
        </div>
    );

    return (
        <div>
            <Header title="Le blog" subTitle="de Code Insider" image={headerImg} />
            {articlesQuery.isLoading && (
                <div className="flex flex-col items-center justify-center">
                    <Lottie animationData={rocketAnimation} loop={true} width={'25%'} height={'50%'} />
                    <p>Les articles arrivent !</p>
                </div>
            )}
            <div className="mx-auto mb-16 w-full p-3 md:w-5/6">
                <div className="flex flex-col gap-3 lg:flex-row">
                    {articlesQuery.isSuccess && (
                        <div>
                            {pageNumber > 1 && <Pagination />}
                            <div>
                                {articlesQuery.data?.items
                                    ?.map((article) => (
                                        <div key={article.id} className="md:max-h-42">
                                            <WordpressHightlight article={article} />
                                        </div>
                                    ))
                                    .reduce((prev, curr) => (
                                        <>
                                            {prev} <hr /> {curr}{' '}
                                        </>
                                    ))}
                            </div>
                            <Pagination />
                        </div>
                    )}
                    <div>
                        <h2 className="block lg:hidden">Découvrez aussi</h2>
                        <SocialMediaSidebarContainer>
                            <PodcastMesonryTile count={1} withLink />
                            <VideosMesonryTile count={1} withLink />
                            <TwitterMesonryTile height={400} />
                        </SocialMediaSidebarContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}
