import React from 'react';
import { Image } from 'react-bootstrap';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import Button from '../../Button';
import Code from '../../Code';
import Events from '../../Events';
import TriangleContainer from '../../TriangleContainer';
import AXA from './Images/Partners/axa.png';
import BNP from './Images/Partners/bnp.png';
import Bollore from './Images/Partners/bollore.png';
import Bouygues from './Images/Partners/bouygues.png';
import CanalPlus from './Images/Partners/canal-plus.png';
import CreditAgricole from './Images/Partners/creditagricole.png';
import Dassault from './Images/Partners/dassault.png';
import Docapost from './Images/Partners/docapost.png';
import FnacDarty from './Images/Partners/fnac-darty.png';
import Microsoft from './Images/Partners/microsoft.png';
import Natixis from './Images/Partners/natixis.png';
import Rothshild from './Images/Partners/rothshild.png';
import SeLoger from './Images/Partners/seloger.png';
import SNCF from './Images/Partners/sncf.png';
import SocieteGenerale from './Images/Partners/societe-generale.png';
import TF1 from './Images/Partners/tf1.png';
import Thales from './Images/Partners/thales.png';
import TUI from './Images/Partners/tui.png';
import Veepee from './Images/Partners/veepee.png';
import Veolia from './Images/Partners/veolia.png';
import Toolbox from './Images/accueil_boite_a_outil.png';
import Astronauta from './Images/astronauta.png';
import CleanCode from './Images/clean-code.png';
import Cosmonauta from './Images/cosmonauta.png';
import Dragon from './Images/dragon.png';
import LogoHeader from './Images/logo-header.png';
import './index.css';

const IndexCode = [
    '<blue>namespace</blue> CodeInsider',
    '{',
    '   <blue>class</blue> ExpertsMicrosoft',
    '   {',
    '       <blue>static void</blue> Main(<blue>string[]</blue> args)',
    '       {',
    '           <green>Console</green>.WriteLine(<red>"Bienvenue sur le site Code Insider"</red>);',
    '       }',
    '   }',
    '}',
];

export default function PageIndex() {
    useDocumentTitle('Code Insider');

    return (
        <>
            <div id="indexHeader" role="banner">
                <Image className="image1" src={LogoHeader} alt="Logo" />
                <div id="indexHeaderContainer">
                    <div id="indexHeaderCode">
                        <Code content={IndexCode} typeSpeed={30} />
                    </div>
                    <div id="indexHeaderText">
                        Société de conseil experte des technologies Microsoft et des méthodologies de développement
                        logiciel avancées
                    </div>
                </div>
            </div>
            <div style={{ paddingBottom: '2em' }}>
                <h1>L'ADN de Code Insider ? Le Software Craftsmanship</h1>
                <div id="indexAddition">
                    <Image width="250" src={Cosmonauta} alt="Astronaute" />
                    <h1>+</h1>
                    <Image width="250" src={CleanCode} alt="Clean Code" />
                </div>
            </div>
            <div className="bg-light">
                <h1>L'expertise de Code Insider</h1>
                <div className="splitContainer">
                    <div>
                        <p className="text-centered textSizingSplit">
                            Code Insider est une ESN spécialisée dans l'Artisanat du Code (le "Software Craftsmanship"),
                            c'est à dire les meilleures techniques et pratiques du développement logiciel.
                            <br />
                            Nos collaborateurs sont tous des développeurs passionnés, ayant la même envie de créer du
                            code propre et testé (Clean Code), de partager leurs connaissances, et de faire de la veille
                            technologique !
                            <br />
                            <br />
                            Nous fédérons au sein de Code Insider les meilleurs développeurs, experts, architectes et
                            référents de la communauté Microsoft.
                            <br />
                            Chez Code Insider, nos développeurs bannissent le "quick & dirty", et produisent des
                            systèmes de haute qualité, de par leur niveau technique très solide, des méthodologies
                            innovantes (TDD, BDD, DDD...), et les techniques les plus avancées du développement
                            logiciel.
                        </p>
                    </div>
                    <div>
                        <p className="text-centered textSizingSplit" style={{ paddingBottom: '5em' }}>
                            «Le problème avec le "vite et sale", c'est que le "sale" demeure bien longtemps après que le
                            "vite" ait été oublié»
                            <br />
                            (Steve McConnell)
                        </p>
                    </div>
                </div>
            </div>
            <div className="indexPictures bg-light">
                <Image src={Astronauta} height="200" alt="Astronaute" style={{ zIndex: '1' }} />
                <Image src={Dragon} height="200" alt="Dragon" style={{ zIndex: '1' }} />
            </div>
            <TriangleContainer behind>
                <h2>Notre boîte à outils</h2>
                <div className="splitContainer">
                    <div>
                        <Image
                            src={Toolbox}
                            className="imageSizingSplit"
                            style={{ paddingBottom: '2em' }}
                            alt="Toolbox"
                        />
                    </div>
                    <div>
                        <p className="justified textSizingSplit">
                            Code Insider propose à ses collaborateurs une véritable boîte à outils pour se former
                            continuellement, tout au long de leur parcours professionnel. Cette boîte à outils est
                            ouverte à tous les Insiders, peu importe leur ancienneté, et ce tout au long de leur
                            carrière chez Code Insider.
                            <br />
                            Parmis nos outils pour monter en compétences :
                            <br />
                        </p>
                        <p
                            className="text-centered textSizingSplit"
                            style={{ paddingBottom: '2em', fontWeight: 'bold' }}
                        >
                            Katas, dojos, jams de code, hackathons, meet-ups, certifications, conférences, projets de
                            Recherche et Développement, MOOC, formations (internes et externes)…
                        </p>
                    </div>
                </div>
            </TriangleContainer>
            <div>
                <Events />
                <Button link="/events">Voir tous les évènements</Button>
            </div>
            <div className="splitContainer affiliation">
                <div>
                    <p className="affiliation-text">
                        <b>Code Insider</b> est une entreprise du groupe Blue Soft depuis février 2023.
                    </p>
                </div>
                <div>
                    <div className="buttonContainer">
                        <a className="button button-affiliation" href="https://www.bluesoft-group.com/">
                            Découvrir le groupe Blue Soft
                        </a>
                    </div>
                </div>
            </div>
            <div className="text-center" id="partners">
                <h1>Ils nous font confiance</h1>
                <Image className="partner" src={AXA} alt="AXA" />
                <Image className="partner" src={BNP} alt="BNP" />
                <Image className="partner" src={Bollore} alt="Bolloré" />
                <Image className="partner" src={Bouygues} alt="Bouygues" />
                <Image className="partner" src={CanalPlus} alt="Canal +" />
                <Image className="partner" src={CreditAgricole} alt="Crédit Agricole" />
                <Image className="partner" src={Dassault} alt="Dassault" />
                <Image className="partner" src={Docapost} alt="Docapost" />
                <Image className="partner" src={FnacDarty} alt="Fnac - Darty" />
                <Image className="partner" src={Microsoft} alt="Microsoft" />
                <Image className="partner" src={Natixis} alt="Natixis" />
                <Image className="partner" src={Rothshild} alt="Rothschild" />
                <Image className="partner" src={SeLoger} alt="SeLoger" />
                <Image className="partner" src={SNCF} alt="SNCF" />
                <Image className="partner" src={SocieteGenerale} alt="Société Générale" />
                <Image className="partner" src={TF1} alt="TF1" />
                <Image className="partner" src={Thales} alt="Thales" />
                <Image className="partner" src={TUI} alt="TUI" />
                <Image className="partner" src={Veepee} alt="Veepee" />
                <Image className="partner" src={Veolia} alt="Veolia" />
            </div>
        </>
    );
}
