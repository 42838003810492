import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import getPosts from '../../../Apis/wordpress';
import { WordpressPreview } from '../../Embeds/wordpress';
import { SocialMediaCardContainer } from '../SocialMediaCardContainer';

export function BlogMesonryTile({
    skipFirstPost = false,
    withLink = false,
    count = 3,
}: {
    skipFirstPost?: boolean;
    withLink?: boolean;
    count?: number;
}) {
    const offset = skipFirstPost ? 1 : 0;

    const wordpressQuery = useQuery({
        queryKey: ['wordpress', 'posts', `page=1`, `per_page=4`],
        queryFn: () => getPosts({ page: 1, perPage: 4 }),
    });

    return (
        <SocialMediaCardContainer name="Blog" url={withLink ? '/blog' : undefined}>
            {wordpressQuery.data?.items
                .slice(offset, offset + count)
                .map((article) => <WordpressPreview key={article.id} article={article} />)
                .reduce(
                    (acc, curr, index) => (
                        <>
                            {index > 0 && (
                                <>
                                    {acc}
                                    <hr />
                                </>
                            )}{' '}
                            {curr}
                        </>
                    ),
                    <></>,
                )}
        </SocialMediaCardContainer>
    );
}
