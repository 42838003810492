import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { SocialMediaMosaicProps } from './SocialMediaMosaicProps';

export function SocialMediaMosaicContainer({
    columnsCountBreakPoints,
    children,
}: SocialMediaMosaicProps & { children: React.ReactNode }) {
    const defaultBreakPoints = { 350: 1, 750: 2, 900: 3 };

    return (
        <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints ?? defaultBreakPoints}>
            <Masonry>{children}</Masonry>
        </ResponsiveMasonry>
    );
}
