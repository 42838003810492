import React from 'react';

import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import Events from '../../Events';
import Header from '../../Header';
import HeaderImage from './Images/header.png';

export default function PageEvents() {
    useFormattedDocumentTitle('Nos Dojos à évènement');

    return (
        <>
            <Header title="Nos Dojos à évènement" subTitle="des Insiders" image={HeaderImage} />
            <Events />
        </>
    );
}
