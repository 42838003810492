import React from 'react';

import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import Header from '../../Header';
import HeaderImage from './Images/header.png';

export default function PageLegal() {
    useFormattedDocumentTitle('Mentions légales');

    return (
        <div>
            <Header title="Mentions légales" subTitle="" image={HeaderImage} />
            <div className="container py-5">
                <p>
                    En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie
                    numérique, il est précisé aux utilisateurs du site{' '}
                    <a href="https://codeinsider.fr/mentions-legales">https://codeinsider.fr/mentions-legales</a>{' '}
                    l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
                </p>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <h2 className="ml-0 py-3 text-left">Éditeur</h2>
                        <p>
                            <b>Code Insider SAS</b>
                            <br />
                            Siège social : 14 Pl. de la Coupole – 94220 Charenton-Le-Pont – France <br />
                            Capital social de 150 000,00 € <br />
                            Contact téléphone : +33 1 40 57 64 20 <br />
                            Contact email : info@codeinsider.fr <br />
                            RCS Nanterre – SIRET n° 809 126 857 000 22 <br />
                            N° de TVA intracommunautaire : FR 09 809 126 857 <br />
                            Responsables de la publication : César Mourot et Benjamin Vidon, en qualité de co-fondateur{' '}
                            <br />
                        </p>
                    </div>

                    <div className="col-12 col-md-6">
                        <h2 className="ml-0 py-3 text-left">Hébergeur</h2>
                        <p>
                            <b>OVH – SAS</b> <br />
                            Siège social : 2, Rue Kellermann – 59100 Roubaix – France <br />
                            Contact téléphone : 08 20 69 87 65 <br />
                            RCS Lille Métropole 424 761 419 00045 <br />
                            Code APE 2620Z <br />
                            N° TVA intracommunautaire : FR 22 424 761 419
                            <br />
                        </p>
                    </div>
                </div>

                <h2 className="ml-0 py-3 text-left">
                    1. Conditions générales d’utilisation du site et des services proposés.
                </h2>
                <p>
                    L’utilisation du site <a href="https://codeinsider.fr/">https://codeinsider.fr/</a> implique
                    l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Le site{' '}
                    <a href="https://codeinsider.fr/mentions-legales/">https://codeinsider.fr/mentions-legales/</a> a
                    pour objet de fournir une information concernant l’ensemble des activités de la société. Code
                    Insider s’efforce de fournir sur le site{' '}
                    <a href="https://codeinsider.fr/mentions-legales/">https://codeinsider.fr/mentions-legales/</a> des
                    informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des
                    omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du
                    fait des tiers partenaires qui lui fournissent ces informations. Ces conditions d’utilisation sont
                    susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site{' '}
                    <a href="https://codeinsider.fr/mentions-legales">https://codeinsider.fr/mentions-legales</a> sont
                    donc invités à les consulter de manière régulière.
                </p>

                <h2 className="ml-0 py-3 text-left">2. Propriété intellectuelle</h2>
                <p>
                    Tous les textes, logos, marques, images fixes ou animées, photographies, illustrations, sons,
                    musiques, noms de domaine et signes distinctifs présents sur le site sont protégés par les droits de
                    propriété intellectuelle et demeurent la propriété exclusive de Code Insider. Toute représentation
                    ou reproduction des éléments du site faite sans le consentement de Code Insider est illicite et
                    constitue une contrefaçon pouvant donner lieu à des poursuites judiciaires. De plus, aucun élément
                    composant le site ne peut être copié, reproduit, modifié, réédité, chargé, dénaturé, transmis ou
                    distribué de quelque manière que ce soit, sur quelque support que ce soit, de façon partielle ou
                    intégrale, sans l’autorisation écrite et préalable de Code Insider à l’exception d’une stricte
                    utilisation pour les besoins de la presse et sous réserve du respect des droits de propriété
                    intellectuelle et de tout autre droit de propriété dont il est fait mention. Seule la copie à usage
                    privé est autorisée pour un usage personnel, privé et non-commercial, sur l’ordinateur personnel du
                    visiteur. Tous les logos, marques, noms de domaine et signes distinctifs présents sur le site sont
                    protégés par les droits de propriété intellectuelle et demeurent la propriété exclusive de Code
                    Insider. D'autres marques, logos peuvent également être citées. Elles sont utilisées par la Société
                    ou l’une des Sociétés du Groupe soit avec l'autorisation de leur titulaire, soit comme simple
                    indication de produits ou services existants. Les marques ou logos mentionnés ne peuvent être
                    utilisés sans l'autorisation écrite préalable desdites sociétés propriétaires.
                </p>

                <h2 className="ml-0 py-3 text-left">3. Nature des informations et limitation de responsabilité</h2>
                <p>
                    Les informations contenues sur le site sont données aux seules fins d’évaluation et ne doivent pas
                    être prises par les visiteurs ou qui que ce soit d’autre comme des données fiables ou assorties
                    d’une quelconque forme de garantie. De ce fait, l’utilisation du site s’effectue entièrement aux
                    propres risques et sous la responsabilité exclusive du visiteur. Les informations sont données « en
                    l’état », sans garantie ou responsabilité d’aucune sorte, expresse ou implicite. Code Insider et/ou
                    ses fournisseurs ne porteront aucune responsabilité pour tout type de dommages liés à l’utilisation
                    des informations rendues disponibles au moyen du site. En outre, et à l’exception des dommages
                    directs résultant d’une faute lourde ou intentionnelle de Code Insider, ce dernier décline toute
                    responsabilité : • Pour toute imprécision, inexactitude ou omission portant sur des informations
                    disponibles sur le site ; • Pour toute interprétation erronée des informations disponibles sur le
                    site ; • Pour tous dommages résultant d’une intrusion frauduleuse d’un tiers, ayant entraîné une
                    modification des informations ou éléments mis à la disposition sur le site ; • et plus généralement
                    pour tous dommages, directs ou indirects, quelles qu’en soient les causes, origines, natures ou
                    conséquences, quand bien même Code Insider aurait été avisé de la possibilité de tels dommages,
                    provoqués en raison de l’accès de quiconque au site ou de l’impossibilité d’y accéder, de
                    l’utilisation du site, incluant toutes détériorations ou virus qui pourraient infecter l’équipement
                    informatique de l’utilisateur ou tout autre bien, et/ou du crédit accordé à une quelconque
                    information provenant directement ou indirectement du site. Toute transaction faite au moyen du site
                    de Code Insider ou basée sur les informations présentes sur le site seront de la seule
                    responsabilité du visiteur. Code Insider ne peut garantir l’exactitude, la précision, la mise à jour
                    ou l’exhaustivité des informations mises à la disposition sur le site. Code Insider est en droit, à
                    son entière discrétion, d’ajouter, supprimer ou modifier à tout moment tout ou partie des services
                    inclus dans le site. Tous les éléments, et notamment les configurations, affichages et écrans, et
                    toutes les informations apparaissant sur ou affiché(e)s par Code Insider en liaison avec ou
                    contenu(e)s sur le site sont protégé(e)s de façon mondiale par toutes les lois applicables. Vous
                    reconnaissez utiliser les Informations fournies sur le Site sous votre responsabilité exclusive et
                    assumez pleinement les risques liés au crédit que vous leur accordez. Tout téléchargement de
                    documents sur le Site est réalisé à vos risques et périls. Vous serez seul responsable pour tout
                    dommage éventuel subi par votre ordinateur ou toute perte de données consécutifs au téléchargement
                    de ces documents.
                </p>

                <h2 className="ml-0 py-3 text-left">4. Droit d’auteur</h2>
                <p>
                    Tous les logiciels, informations, noms, dénominations commerciales, marques de produits, et en
                    général tous les contenus commerciaux utilisés dans les produits ou le site de Code Insider sont la
                    propriété exclusive de leurs propriétaires, en conformité avec le droit d’auteur. Sont désignés
                    comme propriétaires, les éditeurs de logiciels, et de manière générale, tous les ayants-droits
                    associés à Code Insider dans le cadre de l’exécution du présent contrat. Tous les programmes et
                    logiciels sont soumis à des licences accordées par les propriétaires et non détenues par Code
                    Insider. En conformité avec les termes de cette licence, le client n’est pas autorisé à copier,
                    reproduire, modifier, désassembler ou décompiler son code source ou utilisé en combinaison avec un
                    autre logiciel, sauf si les Conditions de Licence l’autorisent ou si la loi applicable dispose qu’un
                    tel acte constitue un droit auquel il ne peut être dérogé contractuellement. Le client n’est pas non
                    plus autorisé à créer des produits inspirés par ces programmes, services ou fonctionnalités. Le nom
                    ou l’identité du propriétaire ne peut être retiré sans l’autorisation écrite préalable. Outre le
                    droit d’usage privé et non commercial, le client n’est pas autorisé à : Vendre, céder, transmettre
                    ou transférer des copies de produits, utiliser ces produits ou tout autre élément sur les
                    plates-formes publiques (en particulier dans les cafés Internet et des salles de jeux vidéo). Le
                    client déclare qu’il comprend les restrictions énoncées ci-dessus et s’engage à les respecter. Le
                    non-respect de ces directives peut entraîner la suspension ou l’annulation du présent contrat et de
                    tout service qui en résulte. Le client comprend que Code Insider ne peut être tenu responsable de
                    toute violation des restrictions contenues ci-dessus commises par un client. Quiconque aura
                    illégalement copié ou reproduit ces produits publiquement ou participant à de tels actes est
                    passible de poursuites pénales. La reproduction illégale de produits est punie d’un emprisonnement
                    ou d’une amende. Les supports de données copiés illégalement peuvent être confisqués et détruits.
                    Code Insider se réserve également le droit en son propre nom ainsi que celui des propriétaires des
                    droits d’auteur de déposer plainte pour les dommages en vertu du droit civil.
                </p>

                <h2 className="ml-0 py-3 text-left">5. Liens vers d’autres sites</h2>
                <p>
                    Tout éventuel lien hypertexte mis en place dans le cadre de ce site en direction de sites
                    extérieurs, n’engagent en aucun cas la responsabilité Code Insider. Le site peut contenir des liens
                    vers d'autres sites. La Société et/ou les Sociétés du Groupe déclinent formellement toute
                    responsabilité quant aux contenus des sites vers lesquels elles offrent des liens, que ce soient
                    leur qualité, leur nature ou leur fiabilité. Ces liens vous sont proposés en tant que commodité et
                    l'inclusion de tout lien n'implique pas l'affiliation, l'approbation ou l'adoption par la Société
                    et/ou les Sociétés du Groupe de toutes informations qu'il contient. La décision d'activer les liens
                    vous appartient exclusivement.
                </p>

                <h2 className="ml-0 py-3 text-left">6. Disponibilité du Site </h2>
                <p>
                    La Société met tout en œuvre pour que le site soit accessible 24 h / 24 et 7 j / 7. Cependant, les
                    opérations de test, de contrôle et de maintenance ainsi que les éventuelles réponses aux pannes
                    peuvent être effectuées par la Société à tout moment. Par conséquent, la Société se réserve le droit
                    de fermer ou de rendre inaccessible tout ou partie du Site notamment pour des raisons de maintenance
                    ou à la demande de toute autorité légale et en cas de force majeure. La Société ne donne aucune
                    garantie d’adéquation du Site à un usage particulier ou de compatibilité avec tous les systèmes
                    d’information. La Société décline toute responsabilité pour toute interruption ou non-disponibilité
                    du site résultant de toute cause, y compris, mais non limité à des problèmes techniques, défaillance
                    de l'équipement ou du réseau de communication, virus pouvant endommager votre équipement. Une
                    interruption pour raison de maintenance technique peut être toutefois décidée par Code Insider, qui
                    s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de
                    l’intervention. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles
                    s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en
                    prendre connaissance.
                </p>

                <h2 className="ml-0 py-3 text-left">7. Mises à jour des Conditions Générales du Site</h2>
                <p>
                    {' '}
                    La Société peut procéder à la mise à jour des Conditions Générales du site à tout moment et sans
                    préavis, mise à jour qui sera effective immédiatement dès publication. En conséquence, nous vous
                    invitons à consulter régulièrement les dernières Conditions Générales du Site en vigueur au moment
                    où vous vous connectez sur le Site.
                </p>

                <h2 className="ml-0 py-3 text-left">8.Protection des données à caractère personnel</h2>
                <p>
                    Nous sommes vigilants à la protection de vos données personnelles. Pour toutes questions relatives à
                    cela, écrivez à l’adresse suivante : info@codeinsider.fr Le présent site web respecte les règles
                    suivantes : 1. Pas de collecte de données à votre insu 2. Pas de cession à des tiers 3. Pas d’usage
                    des informations collectées au-delà du traitement de la demande ou d’une durée d’un an si vous avez
                    accepté d’être joint ultérieurement. Vous disposez d’un droit d’accès à voir auprès du délégué à la
                    protection des données : info@codeinsider.fr Vous disposez également d’un droit d’interrogation, de
                    rectification, de portabilité et de suppression des données à caractère personnel vous concernant, à
                    exercer auprès du délégué à la protection des données à la même adresse ci-dessus. Vos données à
                    caractère personnel sont collectées suite à l’une des deux actions suivantes : 1. L’acceptation des
                    cookies 2. L’abonnement aux articles 3.Les informations soumises dans candidature spontanée Dans
                    tous les cas évoqués ci-dessus, vos données à caractère personnel collectées ne sont ni cédées ni
                    vendues ni utilisées pour une autre finalité que celle pour laquelle elles ont été collectées.
                </p>

                <h2 className="ml-0 py-3 text-left">9. Données du Compte Personnel</h2>
                <p>
                    Le client est seul responsable de la saisie de ses données d’enregistrement et s’engage à
                    communiquer des informations valides le concernant. Si des incohérences dans ces données
                    personnelles sont constatées, Code Insider se réserve le droit de demander des documents
                    justificatifs d’identité et de suspendre la validation de la commande le temps de l’enquête. Après
                    cette enquête, Code Insider peut déterminer à sa discrétion et de façon raisonnable les mesures à
                    prendre. En cas de récidive de cette violation des présentes conditions générales de vente, Code
                    Insider se réserve le droit de désactiver l’accès au service et de rompre le contrat conclu avec le
                    client, sans préavis ni indemnité.
                </p>

                <h2 className="ml-0 py-3 text-left">10. Droit applicable</h2>
                <p>
                    Le contenu du site de Code Insider est régi par la loi française. En cas de conflit, et après
                    l'échec de toute tentative de recherche d'une solution amiable, les tribunaux français seront seuls
                    compétents pour connaître de ce litige.
                </p>

                <h2 className="ml-0 py-3 text-left">11. Cookies</h2>
                <p>
                    Code Insider utilise des cookies pendant que vous naviguez sur son site Web. Un cookie est un
                    fichier composé de chiffres et de lettres qui est stocké sur votre navigateur. Lors de vos visites
                    sur un site internet, ces sites peuvent déposer des cookies sur votre navigateur afin d’y
                    sauvegarder des informations permettant d’optimiser et de personnaliser votre utilisation du site.
                    Lorsque vous naviguez sur ce site, des cookies peuvent être installés et lus sur votre équipement
                    terminal afin d’améliorer la qualité de service. Les données des clients seront stockées et traitées
                    conformément aux lois applicables concernant la protection des données. Les clients ont le droit à
                    tout moment de revoir, corriger, et supprimer les données qui ont été stockées gratuitement. Tout
                    utilisateur peut s’opposer à l’enregistrement des informations relatives à sa navigation sur le site
                    internet en configurant son navigateur. Code Insider ne partage pas les renseignements personnels, y
                    compris les adresses postales et e-mail avec des tiers sans le consentement du client, pouvant être
                    révoquée à tout moment, sauf dans le cas d’une livraison physique où Code Insider peut communiquer
                    ces informations à un tiers. La gestion des cookies peut également se faire de la manière décrite
                    par la CNIL à l’adresse suivante :{' '}
                    <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">
                        https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser
                    </a>{' '}
                    . Le site internet peut donner accès, par des liens hypertextes, à d’autres sites web publiés par
                    des tiers qui sont susceptibles d’utiliser leur propre politique de gestion des cookies pour tracer
                    la navigation de leurs visiteurs.
                </p>
            </div>
        </div>
    );
}
