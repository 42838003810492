import React, { Component } from 'react';

import './index.css';

export default class Button extends Component {
    render() {
        if (typeof this.props.link == 'function')
            return (
                <div className="buttonContainer" style={this.props.inline && { display: 'inline-block' }}>
                    <div className={'button ' + this.props.variant} onClick={this.props.link}>
                        {this.props.children}
                    </div>
                </div>
            );
        else
            return (
                <div className="buttonContainer" style={this.props.inline && { display: 'inline-block' }}>
                    <a className={'button ' + this.props.variant} href={this.props.link}>
                        {this.props.children}
                    </a>
                </div>
            );
    }
}
