import { Formik } from 'formik';
import { useState } from 'react';
import { Alert, Button, Card, Col, Form } from 'react-bootstrap';

import { ApplicationFormApi } from '../../../../api';
import useApi from '../../../../hooks/useApi';
import FormBackground from '../Images/formBackground.png';
import './ApplicationForm.css';

export function ApplicationForm({
    className,
    offer,
    onSuccess,
}: {
    className?: string;
    offer?: {
        title: string;
    };
    onSuccess: () => void;
}) {
    const applicationFormApi = useApi(ApplicationFormApi);

    const [showAlert, setShowAlert] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>();

    const initialValues = {
        prenom: '',
        nom: '',
        email: '',
        phone: '',
        comment: '',
    };

    return (
        <>
            <Card className={`application-form text-light ${className}`}>
                <Card.Img src={FormBackground} alt="Card image" className="object-cover object-top" />
                <Card.ImgOverlay>
                    <Card.Title className="my-4 text-center">
                        {offer ? offer.title : 'Candidature spontanée'}
                    </Card.Title>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={async (values, { setSubmitting }) => {
                            await applicationFormApi
                                .sendApplicationConfirmationEmailRaw({
                                    websiteResourcesApplicationForm: {
                                        prenom: values.prenom,
                                        nom: values.nom,
                                        email: values.email,
                                        phone: values.phone,
                                        comment: values.comment,
                                        offerName: offer?.title,
                                    },
                                })
                                .then((res) => {
                                    setIsSuccess(res.raw.status === 200);
                                    setShowAlert(true);
                                    onSuccess();
                                });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form id="application-form" onSubmit={handleSubmit}>
                                <Form.Row className="form-group">
                                    <Col>
                                        <Form.Label>Prénom</Form.Label>
                                        <Form.Control
                                            required
                                            name="prenom"
                                            value={values.prenom}
                                            onChange={handleChange}
                                        />
                                        {errors.prenom && <div className="text-red-800">{errors.prenom}</div>}
                                    </Col>
                                    <Col>
                                        <Form.Label>Nom</Form.Label>
                                        <Form.Control required name="nom" value={values.nom} onChange={handleChange} />
                                        {errors.nom && <div className="text-red-800">{errors.nom}</div>}
                                    </Col>
                                </Form.Row>
                                <Form.Row className="form-group">
                                    <Col>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            required
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email && <div className="text-red-800">{errors.email}</div>}
                                    </Col>
                                    <Col>
                                        <Form.Label>Téléphone</Form.Label>
                                        <Form.Control
                                            required
                                            name="phone"
                                            value={values.phone}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Row>
                                <Form.Group>
                                    <Form.Label>Parlez nous de vous</Form.Label>
                                    <Form.Control
                                        name="comment"
                                        as="textarea"
                                        style={{ resize: 'none' }}
                                        rows={9}
                                        value={values.comment}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Button type="submit" block variant="light" className="mb-auto" active={isSubmitting}>
                                    {isSubmitting ? 'Envoi ...' : 'Postuler'}
                                </Button>

                                <Alert
                                    show={showAlert}
                                    onClose={() => setShowAlert(false)}
                                    dismissible
                                    variant={isSuccess ? 'success' : 'danger'}
                                    style={{
                                        position: 'fixed',
                                        bottom: 0,
                                        right: 0,
                                        margin: 30,
                                        zIndex: 5076,
                                    }}
                                >
                                    {isSuccess ? (
                                        <span>Nous avons bien reçu votre candidature !</span>
                                    ) : (
                                        <span>"Une erreur s'est produite. Veuillez réessayer plus tard"</span>
                                    )}
                                </Alert>
                            </Form>
                        )}
                    </Formik>
                </Card.ImgOverlay>
            </Card>
        </>
    );
}
