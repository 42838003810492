import moment from 'moment';
import 'moment/locale/fr';
import React, { Component } from 'react';

import './index.css';

moment.locale('fr');

class Event extends Component {
    getMonthName() {
        let name = moment(this.props.date).format('MMMM');
        return name[0].toUpperCase() + name.substring(1);
    }

    render() {
        if (this.props.small)
            return (
                <a className="eventSmall" href={'/event?id=' + this.props.id}>
                    <div className="eventSmallBubble">
                        <div className="eventSmallDateNum">{moment(this.props.date).date()}</div>
                        <div className="eventSmallDateMonth">{this.getMonthName()}</div>
                    </div>
                    <div className="eventSmallInfo">
                        <div className="eventSmallName">{this.props.name}</div>
                        <div className="eventSmallLoc">{this.props.location}</div>
                    </div>
                </a>
            );
        return (
            <a className="event" href={'/event?id=' + this.props.id}>
                <div className="eventDateNum">{moment(this.props.date).date()}</div>
                <div className="eventDateMonth">{this.getMonthName()}</div>
                <div className="eventBorder"></div>
                <div className="eventName">{this.props.name}</div>
                <div className="eventLoc">{this.props.location}</div>
            </a>
        );
    }
}

Event.List = class extends Component {
    render() {
        return <div className="eventList">{this.props.children}</div>;
    }
};

export default Event;
