import axios from 'axios';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import Header from '../../Header';
import TriangleContainer from '../../TriangleContainer';
import HeaderImage from './Images/header_dojo.png';

class PageEvent extends Component {
    state = {
        idEvent: 0,
        isImportant: false,
        titreEvent: '',
        nomEvent: '',
        dateFinEvent: '',
        dateStartEvent: '',
        lieuEvent: '',
        descriptionEvent: '',
        prenom: '',
        nom: '',
        email: '',
        phone: '',
        redirect: null,
        output: '',
    };

    constructor(props) {
        super(props);

        var query = this.props.location.search;
        var id = this.extractId(query);
        this.setEventData(id);
    }

    extractId(query) {
        var param = query.split('=');
        param[0] = param[0].replace('?', '');
        if (param[0] !== 'id') return null;
        var id = parseInt(param[1]);
        if (isNaN(id)) return null;
        return id;
    }

    getCurrentDate() {
        var currentDate = new Date();
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        let hour = currentDate.getHours();
        let minute = currentDate.getMinutes();
        return year + '-' + month + '-' + day + 'T' + hour + ':' + minute;
    }

    setEventData(id) {
        axios
            .get('api/Evenements/' + id)
            .then((res) => {
                var data = res.data;
                this.setState({
                    idEvent: id,
                    isImportant: data.importantEvent,
                    titreEvent: data.titreEvent,
                    nomEvent: data.nomEvent,
                    dateFinEvent: data.dateFinEvent,
                    dateStartEvent: data.dateStartEvent,
                    lieuEvent: data.lieuEvent,
                    descriptionEvent: data.descriptionEvent,
                    redirect: null,
                });
                console.log(this.state);
            })
            .catch((error) => {
                this.setState({ redirect: '/events' });
            });
    }

    //Signup form
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSubmit = (event) => {
        //Send the signup form by mail
        axios
            .post(
                'api/Evenements/register',
                {
                    prenomUser: this.state.prenom,
                    nomUser: this.state.nom,
                    emailUser: this.state.email,
                    telUser: this.state.phone,
                },
                {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                },
            )
            .then((res) => {
                this.setState({
                    output: <p style={{ color: 'white', textAlign: 'center' }}>Vous êtes inscrit, merci !</p>,
                });
            })
            .catch((error) => {
                this.setState({
                    output: (
                        <p style={{ color: 'white', textAlign: 'center' }}>
                            Une erreur est survenue, merci de réessayer.
                        </p>
                    ),
                });
            });
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }
        return (
            <>
                <Header title={this.state.titreEvent} subTitle="" image={HeaderImage} />
                <div
                    className="splitContainer"
                    style={{ background: '#f7f7f7', alignItems: 'start', padding: '1em 0' }}
                >
                    <div>
                        <h3>Date/Heure</h3>
                        <p className="textSizingSplit text-centered">
                            Debut: {this.state.dateStartEvent.replace('T', ' ')}
                            <br />
                            Fin: {this.state.dateFinEvent.replace('T', ' ')}
                        </p>
                    </div>
                    <div>
                        <h3>Emplacement</h3>
                        <p className="textSizingSplit text-centered">{this.state.lieuEvent}</p>
                    </div>
                </div>
                <div>
                    <h2>{this.state.nomEvent}</h2>
                    <p
                        className="textSizing textSmallSize text-justify"
                        style={{ marginBottom: '10em', marginTop: '1em' }}
                    >
                        {this.state.descriptionEvent}
                    </p>
                </div>
                <TriangleContainer>
                    {this.getCurrentDate() < this.state.dateStartEvent ? (
                        <h2 style={{ padding: '4em 0' }}>Evénement terminé</h2>
                    ) : (
                        <>
                            <div className="recrutingSplitContainer">
                                <div className="recruitingTextFieldContainer">
                                    <input
                                        name="prenom"
                                        className="textField"
                                        type="text"
                                        placeholder="Prénom"
                                        onChange={this.handleChange}
                                        style={{ margin: '1em' }}
                                    />
                                    <input
                                        name="nom"
                                        className="textField"
                                        type="text"
                                        placeholder="Nom"
                                        onChange={this.handleChange}
                                        style={{ margin: '1em' }}
                                    />
                                </div>
                                <div className="recruitingTextFieldContainer">
                                    <input
                                        name="email"
                                        className="textField"
                                        type="email"
                                        placeholder="Email"
                                        onChange={this.handleChange}
                                        style={{ margin: '1em' }}
                                    />
                                    <input
                                        name="phone"
                                        className="textField"
                                        type="tel"
                                        placeholder="N° de téléphone"
                                        onChange={this.handleChange}
                                        style={{ margin: '1em' }}
                                    />
                                </div>
                            </div>
                            <div
                                className="buttonContainer"
                                style={{ display: 'block', maxWidth: '200px', width: '40%' }}
                            >
                                <div className="button light" onClick={this.handleSubmit}>
                                    S'inscrire
                                </div>
                            </div>
                            {this.state.output}{' '}
                        </>
                    )}
                </TriangleContainer>
            </>
        );
    }
}

export default function WrappedPageEvent(props) {
    // TODO: make dynamic
    useFormattedDocumentTitle("Détails de l'évènement");
    return <PageEvent {...props} />;
}
