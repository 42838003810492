import sanitizeHtml, { type IOptions } from 'sanitize-html';

const defaultOptions = {
    allowedTags: ['b', 'i', 'em', 'strong', 'a'],
    allowedAttributes: {
        a: ['href'],
    },
    allowedIframeHostnames: ['www.youtube.com'],
};

const sanitize = (dirty: string, options?: IOptions) => ({
    __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
});

export default function SanitizeHTML({ html, options }: { html: string | null; options?: IOptions }) {
    return html ? <div dangerouslySetInnerHTML={sanitize(html!, options)} /> : null;
}
