/* tslint:disable */
/* eslint-disable */
/**
 * CodeInsider API
 * L\'API du site de Code Insider.
 *
 * The version of the OpenAPI document: v1
 * Contact: codeinsider@codeinsider.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode,
} from '../models';
import {
    SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisodeFromJSON,
    SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisodeToJSON,
} from '../models';

export interface GetSpotifyEpisodesRequest {
    limit?: number;
    offset?: number;
}

/**
 * 
 */
export class SpotifyApi extends runtime.BaseAPI {

    /**
     * Get the list of spotify podcast episodes
     */
    async getSpotifyEpisodesRaw(requestParameters: GetSpotifyEpisodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Spotify/episodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisodeFromJSON(jsonValue));
    }

    /**
     * Get the list of spotify podcast episodes
     */
    async getSpotifyEpisodes(requestParameters: GetSpotifyEpisodesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpotifyAPIWebPagingOfSpotifyAPIWebSimpleEpisode> {
        const response = await this.getSpotifyEpisodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
