import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import Logo from './Images/logo-menu.png';
import './index.css';

export default class MenuBar extends Component {
    state = {
        reduced: false,
    };

    renderDropDownItems(element) {
        var res = [];
        var id = 0;

        if (element.menus)
            element.menus.forEach((elem) => {
                res.push(
                    <a key={id} target={elem.newTab && '_blank'} href={elem.link}>
                        {elem.name}
                    </a>,
                );
                ++id;
            });
        // if (element.link && element.name !== "Accueil")
        //     res.push(
        //         <a key={id} target={element.newTab && "_blank"} href={element.link}>{element.name}</a>
        //     );
        return res;
    }

    renderMenuDropdown(element) {
        return (
            <div key={element.name} className="menuBarItem">
                {element.link ? <a href={element.link}>{element.name}</a> : element.name}
                <div className="menuBarItemDropDown">{this.renderDropDownItems(element)}</div>
            </div>
        );
    }

    renderMenuItem(element) {
        var res = [];

        if (!element.newTab) {
            res.push(
                <div key={element.link} className="menuBarItem">
                    <a target={element.newTab && '_blank'} href={element.link}>
                        {element.name}
                    </a>
                </div>,
            );
        } else {
            res.push(
                <div key={element.link} className="menuBarItem newTab">
                    <a target={element.newTab && '_blank'} href={element.link}>
                        {element.name}
                    </a>
                </div>,
            );
        }
        return res;
    }

    renderMenuButtons() {
        var res = [];

        this.props.buttons.forEach((element) => {
            if (element.menus) res.push(this.renderMenuDropdown(element));
            else res.push(this.renderMenuItem(element));
        });
        return res;
    }

    renderMenuItemsButtons() {
        var res = [];

        this.props.buttons.forEach((element) => {
            res.push(this.renderDropDownItems(element));
        });
        return res;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        var val = window.scrollY;
        if (val >= 100 && !this.state.reduced) this.setState({ reduced: true });
        else if (val < 100 && this.state.reduced) this.setState({ reduced: false });
    };

    render() {
        return (
            <nav>
                <div id={this.state.reduced ? 'menuBarReduced' : 'menuBar'}>
                    <a href="/">
                        <Image src={Logo} alt="Code Insider Logo" />
                    </a>
                    <div className="menuBarButton">{this.renderMenuButtons()}</div>
                    <label htmlFor="toggle">&#9776;</label>
                    <input type="checkbox" id="toggle" />
                    <div className="menuBarItemsButton">{this.renderMenuItemsButtons()}</div>
                </div>
            </nav>
        );
    }
}
