import { Route } from 'react-router-dom';

import CookieConsent from './Components/CookieConsent';
import Footer from './Components/Footer';
import MenuBar from './Components/MenuBar';
import BlogPage from './Components/Pages/Blog';
import BlogItemPage from './Components/Pages/BlogItem';
import PageEvent from './Components/Pages/Event';
import PageEvents from './Components/Pages/Events';
import PageHistory from './Components/Pages/History';
import PageIndex from './Components/Pages/Index';
import PageInsider from './Components/Pages/Insider';
import PageJob from './Components/Pages/Job';
import PageLegal from './Components/Pages/Legal';
import PageNews from './Components/Pages/News';
import PodcastsPage from './Components/Pages/Podcasts';
import PageRecruiting from './Components/Pages/Recruiting';
import JobOfferPage from './Components/Pages/Recruiting/JobOffers/JobOffer';
import VideosPage from './Components/Pages/Videos';
import WikiPage from './Components/Pages/Wiki';
import ScrollToTop from './ScrollToTop';

const MENU = [
    {
        name: 'Accueil',
        link: '/',
    },
    {
        name: 'Qui sommes-nous ?',
        link: '/history',
        menus: [
            {
                name: 'Notre histoire',
                link: '/history',
            },
            {
                name: 'Notre métier',
                link: '/job',
            },
            {
                name: "Qu'est ce qu'un Insider ?",
                link: '/insider',
            },
        ],
    },
    {
        name: 'On recrute !',
        link: '/recruiting',
    },
    {
        name: 'Événements',
        link: '/events',
    },
    {
        name: 'Actualités',
        link: '/news',
        menus: [
            {
                name: 'Blog',
                link: '/blog',
            },
            {
                name: 'Vidéos',
                link: '/videos',
            },
            {
                name: 'Podcasts',
                link: '/podcasts',
            },
        ],
    },
];

export default function App() {
    return (
        <>
            <CookieConsent />
            <ScrollToTop />
            <MenuBar buttons={MENU} location={window.location} />
            <div id="bodyContent" className="position-relative">
                <Route exact path="/" component={PageIndex} />
                <Route exact path="/history" component={PageHistory} />
                <Route exact path="/job" component={PageJob} />
                <Route exact path="/insider" component={PageInsider} />
                <Route exact path="/recruiting" component={PageRecruiting} />
                <Route exact path="/recruiting/:id" component={JobOfferPage} />
                <Route exact path="/events" component={PageEvents} />
                <Route exact path="/news" component={PageNews} />
                <Route exact path="/event" render={(props) => <PageEvent {...props} />} />
                <Route exact path="/mentions-legales" component={PageLegal} />
                <Route exact path="/blog" component={BlogPage} />
                <Route exact path="/blog/:id" component={BlogItemPage} />
                <Route exact path="/videos" component={VideosPage} />
                <Route exact path="/podcasts" component={PodcastsPage} />

                <Route exact path="/wiki" component={WikiPage} />
            </div>
            <Footer />
        </>
    );
}
