export type SocialLinkProps = {
    name: string;
    icon: any;
    href: string;
};

export function SocialLink({
    name,
    icon,
    href,
    ...props
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & SocialLinkProps) {
    return (
        <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className="btn flex h-16 w-full basis-1/2 items-center justify-center gap-4 rounded bg-[#014F9B] p-2 font-semibold  shadow-lg hover:bg-[#EFAC3D] hover:shadow-none active:bg-[#EFAC3D]"
            {...props}
        >
            <div className="h-12 w-12">{icon}</div>
            <div className="text-white">{name}</div>
        </a>
    );
}
