import { useQuery } from '@tanstack/react-query';

import { YoutubeApi } from '../../../api';
import astro1 from '../../../assets/images/astro1.png';
import banner1 from '../../../assets/images/banner1.png';
import useApi from '../../../hooks/useApi';
import useFormattedDocumentTitle from '../../../hooks/useFormattedDocumentTitle';
import SanitizeHTML from '../../../util/SanitizeHTML';
import Header from '../../Header';
import YoutubeIcon from '../../Icons/SocialMedias/YoutubeIcon';
import { BlogMesonryTile } from '../../Social/MesonryTiles/BlogMesonryTile';
import { PodcastMesonryTile } from '../../Social/MesonryTiles/PodcastMesonryTile';
import { TwitterMesonryTile } from '../../Social/MesonryTiles/TwitterMesonryTile';
import { SocialMediaSidebarContainer } from '../../Social/SocialMediaSidebarContainer';

function YoutubeChannel() {
    return (
        <div className="flex flex-row items-center gap-3">
            <a href="https://www.youtube.com/@codeinsider4427" target="_blank" rel="noreferrer" className="m-0 p-0">
                <img src={astro1} alt="Astro" className="h-32 w-32" />
            </a>
            <div className="flex flex-col items-start">
                <a
                    href="https://www.youtube.com/@codeinsider4427"
                    target="_blank"
                    rel="noreferrer"
                    className="m-0 p-0 text-2xl text-black no-underline"
                >
                    Code Insider
                </a>
                <p className="m-0 p-0 text-gray-500">@codeinsider4427</p>
                <a
                    href="https://www.youtube.com/@codeinsider4427"
                    target="_blank"
                    rel="noreferrer"
                    className="m-0 flex items-center gap-2 rounded bg-red-500 p-2 text-white"
                >
                    <YoutubeIcon className="h-6 w-6" />
                    <span>Aller sur la chaîne Youtube</span>
                </a>
            </div>
        </div>
    );
}

export default function VideosPage() {
    useFormattedDocumentTitle('Vidéos');
    const youtubeApi = useApi(YoutubeApi);

    const query = useQuery({
        queryKey: ['youtube', 'videos'],
        queryFn: () => youtubeApi.getChannelVideos(),
    });

    return (
        <div className="mb-10">
            <Header title="Videos" image={banner1} className="bg-center" />
            <div className="mx-auto mb-16 w-full p-3 md:w-5/6">
                <div className="flex flex-col gap-3 lg:flex-row">
                    <div>
                        <div className="mx-auto flex justify-start">
                            <YoutubeChannel />
                        </div>
                        <div className="flex flex-wrap items-center justify-center gap-3">
                            {/* {query.data?.pages?.map((page) =>
                            page.items?.map((video) => <YoutubeEmbed key={video.id.videoId} video={video} />),
                        )} */}
                        </div>
                        <div className="my-10 flex flex-row flex-wrap items-stretch justify-center gap-3">
                            {query.data?.map((video) => (
                                <div
                                    key={video.resourceId?.videoId}
                                    className="rounded-lg border border-transparent bg-gray-100 transition-all hover:border-gray-300 hover:shadow-lg"
                                >
                                    {video.resourceId?.videoId && (
                                        <a
                                            href={`https://www.youtube.com/watch?v=${video.resourceId?.videoId}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className=" w-full rounded-t-lg sm:w-[560px]"
                                        >
                                            <img
                                                src={video.thumbnails?.high?.url ?? '#'}
                                                alt="video thumbnail"
                                                className="aspect-video w-full rounded-t-lg object-cover"
                                            />
                                        </a>
                                    )}
                                    <div className="px-4 py-2 sm:w-[560px]">
                                        <a
                                            href={`https://www.youtube.com/watch?v=${video.resourceId?.videoId}`}
                                            className="break-words text-2xl text-black"
                                        >
                                            {video.title}
                                        </a>
                                        <div className="text-ellipsis break-words text-neutral-500 line-clamp-5">
                                            <SanitizeHTML html={video.description ?? ''} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h2 className="block lg:hidden">Découvrez aussi</h2>
                        <SocialMediaSidebarContainer>
                            <PodcastMesonryTile count={1} withLink />
                            <BlogMesonryTile count={1} withLink />
                            <TwitterMesonryTile height={400} />
                        </SocialMediaSidebarContainer>
                    </div>
                </div>
            </div>

            {/* <div className="flex flex-col items-center">
                <button
                    className="rounded-full border-none bg-blue-200 px-5 py-3 text-blue-600"
                    // onClick={() => query.fetchNextPage()}
                >
                    Charger plus
                </button>
                <Lottie animationData={rocketAnimation} loop={true} className="h-1/6 w-1/6" />
            </div> */}

            {/* <div className="flex flex-col items-center">
                {query.hasNextPage && (
                    <button
                        className="rounded-full border-none bg-blue-300 text-blue-600"
                        onClick={() => query.fetchNextPage()}
                    >
                        Load More
                    </button>
                )}
                {query.isFetchingNextPage && <div>Loading...</div>}
            </div> */}
        </div>
    );
}
