import { useMemo } from 'react';

import { Configuration, ConfigurationParameters } from '../api';
import { REACT_APP_API_URL } from '../conf';

/**
 * Returns a configured instance of the API.
 * @param Api the type of the API to return
 * @example `const api = useApi(AccountApi)`
 */
const useApi = <T>(Api: { new (config: Configuration): T }): T => {
    const config = useMemo(() => {
        const base: ConfigurationParameters = {
            basePath: REACT_APP_API_URL,
        };
        return new Configuration({ ...base });
    }, []);
    return useMemo(() => new Api(config), [Api, config]);
};

export default useApi;
